import { useState, useEffect } from 'react'
import { signup, verify_code } from '../request/api'
import landingImage from '../icons/landing.png'
import Logo1 from '../icons/Logo1.png'

export default function Signup() {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [username, setUsername] = useState('')
  const [verificationCode, setVerificationCode] = useState('')
  const [isSending, setIsSending] = useState(false);
  const [countdown, setCountdown] = useState(null);
  const [source, setSource] = useState('');
  const [grade, setGrade] = useState('');
  const waiting_time = 60;

  useEffect(() => {
    let timer = null;
    if (countdown !== null) {
      if (countdown > 0) {
        // Update the countdown every second
        timer = setTimeout(() => setCountdown(countdown - 1), 1000);
      } else {
        // Reset and enable the button when countdown finishes
        setIsSending(false);
        setCountdown(null);
      }
    }
    return () => clearTimeout(timer);
  }, [countdown]);

  const handleVerification = (e) => {
    console.log(e.target.value);
    setVerificationCode(e.target.value);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value)
  };

  const handleUsernameChange = (e) => {
    setUsername(e.target.value)
  };

  const handleRefererSourceChange = (e) => {
    const selectedSource = e.target.value;
    console.log("Selected referer source:", selectedSource);
    setSource(selectedSource);
  }

  const handleGradeChange = (e) => {
    const selectedSource = e.target.value;
    console.log("Selected grade:", selectedSource);
    setGrade(selectedSource);
  }

  async function handleSubmit(e) {
    e.preventDefault(); // Prevent default form submission
    console.log(source);
    console.log(grade);
    // Check if source and grade has value
    if (source == "" || grade == "") {
      alert("Please enter referer source and grade")
    } else {
      await signup(email, username, password, verificationCode, source, grade);
    }
  }

  async function handleSendVerificationCode(e) {
    e.preventDefault(); // Prevent default form submission

    setIsSending(true);
    setCountdown(waiting_time); // Start countdown from 10 seconds

    try {
      await verify_code(email);
      await new Promise(resolve => setTimeout(resolve, 10000));

      setIsSending(false);
    } catch (error) {
      console.error('Error sending verification code:', error);
      setIsSending(false);
      setCountdown(null); // Reset countdown
    }
  }

  return (
    <>
      {/*
          This example requires updating your template:
  
          ```
          <html class="h-full bg-white">
          <body class="h-full">
          ```
        */}
      <div className="flex min-h-full flex-1">
        <div className="flex flex-1 flex-col justify-center px-4 py-12 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
          <div className="mx-auto w-full max-w-sm lg:w-96">
            <div>
              <img
                className="h-10 w-auto"
                src={Logo1}
                alt="Your Company"
              />
              <h2 className="mt-8 text-2xl font-bold leading-9 tracking-tight text-gray-900">
                Sign up to Your Account
              </h2>
            </div>

            <div className="mt-10">
              <div>
                <form action="#" method="POST" className="space-y-6">
                  <div>
                    <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                      Email address
                    </label>
                    <div className="mt-2">
                      <input
                        id="email"
                        name="email"
                        type="email"
                        autoComplete="email"
                        onChange={handleEmailChange}
                        required
                        className="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      />
                    </div>
                  </div>

                  <div className="flex items-center space-x-4">
                    <div className="flex-grow">
                      <label htmlFor="verificationCode" className="block text-sm font-medium leading-6 text-gray-900">
                        Verification Code (Email)
                      </label>
                      <div className="mt-2 flex rounded-md shadow-sm">
                        <input
                          id="verificationCode"
                          name="verificationCode"
                          type="text"
                          onChange={handleVerification}
                          required
                          className="flex-grow rounded-l-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                        <button
                          type="button"
                          onClick={handleSendVerificationCode}
                          disabled={isSending}
                          style={{
                            backgroundColor: isSending ? '#cbd5e0' : '#5a67d8', // Grey out when sending
                            color: 'white',
                            border: 'none',
                            boxShadow: '0 2px 4px -1px rgba(0, 0, 0, 0.1), 0 1px 2px -1px rgba(0, 0, 0, 0.06)',
                            padding: '0.5rem 1rem',
                            borderRadius: '0.375rem',
                            fontSize: '0.875rem',
                            fontWeight: '600',
                            cursor: isSending ? 'default' : 'pointer',
                            transition: 'background-color 0.2s ease-in-out',
                            pointerEvents: isSending ? 'none' : 'auto', // Prevents clicking while sending
                          }}
                          onMouseOver={e => isSending ? null : (e.currentTarget.style.backgroundColor = '#4c51bf')}
                          onMouseOut={e => isSending ? null : (e.currentTarget.style.backgroundColor = '#5a67d8')}
                        >
                          {isSending ? `${countdown}s` : 'Send Code'}
                        </button>
                      </div>
                    </div>
                  </div>


                  <div>
                    <label htmlFor="username" className="block text-sm font-medium leading-6 text-gray-900">
                      Username
                    </label>
                    <div className="mt-2">
                      <input
                        id="username"
                        name="username"
                        type="username"
                        autoComplete="username"
                        onChange={handleUsernameChange}
                        required
                        className="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      />
                    </div>
                  </div>

                  <div>
                    <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900">
                      Password
                    </label>
                    <div className="mt-2">
                      <input
                        id="password"
                        name="password"
                        type="password"
                        autoComplete="current-password"
                        onChange={handlePasswordChange}
                        required
                        className="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      />
                    </div>
                  </div>

                  <div>
                    <label htmlFor="refererSource" className="block text-sm font-medium leading-6 text-gray-900">
                      Referer Source
                    </label>
                    <div className="mt-2">
                      <select
                        id="refererSource"
                        name="refererSource"
                        onChange={handleRefererSourceChange} // Assume you have a handler function for this
                        required
                        className="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      >
                        <option value="">Select a source</option>
                        <option value="xiaohongshu">小红书帖子</option>
                        <option value="wechat-group">微信群</option>
                        <option value="wechat-pyq">同学朋友圈</option>
                        <option value="friend-refer">同学推荐</option>
                        <option value="counselor-refer">老师推荐</option>
                        <option value="zhihu">知乎</option>
                        <option value="xiaohongshu-comment">小红书评论区</option>
                        <option value="other">Other</option>
                      </select>
                    </div>
                  </div>

                  <div>
                    <label htmlFor="grade" className="block text-sm font-medium leading-6 text-gray-900">
                      Grade
                    </label>
                    <div className="mt-2">
                      <select
                        id="grade"
                        name="grade"
                        onChange={handleGradeChange} // Assume you have a handler function for this
                        required
                        className="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      >
                        <option value="">Select a grade</option>
                        <option value="senior">高三</option>
                        <option value="junior">高二</option>
                        <option value="freshman">高一</option>
                        <option value="teacher">老师</option>
                        <option value="other">Other</option>
                      </select>
                    </div>
                  </div>

                  <div className="flex items-center justify-between">
                    <div className="flex items-center">
                      <input
                        id="remember-me"
                        name="remember-me"
                        type="checkbox"
                        className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                      />
                      <label htmlFor="remember-me" className="ml-3 block text-sm leading-6 text-gray-700">
                        Remember me
                      </label>
                    </div>


                  </div>

                  <div>
                    <button
                      onClick={handleSubmit}
                      className="flex w-full justify-center rounded-md  px-3 py-1.5 text-sm font-semibold leading-6 "
                    >
                      Sign up
                    </button>
                  </div>
                </form>
              </div>

              <div className="mt-10">
                <div className="relative">
                  <div className="absolute inset-0 flex items-center" aria-hidden="true">
                    <div className="w-full border-t border-gray-200" />
                  </div>
                  <div className="relative flex justify-center text-sm font-medium leading-6">
                    <span className="bg-white px-6 text-gray-900">Or continue with</span>
                  </div>
                </div>

                <div className="mt-6 grid grid-cols-2 gap-4">
                  <a
                    href="#"
                    className="flex w-full items-center justify-center gap-3 rounded-md bg-[#1D9BF0] px-3 py-1.5 text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#1D9BF0]"
                  >
                    <svg className="h-5 w-5" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20">
                      <path d="M6.29 18.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0020 3.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.073 4.073 0 01.8 7.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 010 16.407a11.616 11.616 0 006.29 1.84" />
                    </svg>
                    <span className="text-sm font-semibold leading-6">Twitter</span>
                  </a>

                  <a
                    href="#"
                    className="flex w-full items-center justify-center gap-3 rounded-md bg-[#24292F] px-3 py-1.5 text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#24292F]"
                  >
                    <svg className="h-5 w-5" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20">
                      <path
                        fillRule="evenodd"
                        d="M10 0C4.477 0 0 4.484 0 10.017c0 4.425 2.865 8.18 6.839 9.504.5.092.682-.217.682-.483 0-.237-.008-.868-.013-1.703-2.782.605-3.369-1.343-3.369-1.343-.454-1.158-1.11-1.466-1.11-1.466-.908-.62.069-.608.069-.608 1.003.07 1.531 1.032 1.531 1.032.892 1.53 2.341 1.088 2.91.832.092-.647.35-1.088.636-1.338-2.22-.253-4.555-1.113-4.555-4.951 0-1.093.39-1.988 1.029-2.688-.103-.253-.446-1.272.098-2.65 0 0 .84-.27 2.75 1.026A9.564 9.564 0 0110 4.844c.85.004 1.705.115 2.504.337 1.909-1.296 2.747-1.027 2.747-1.027.546 1.379.203 2.398.1 2.651.64.7 1.028 1.595 1.028 2.688 0 3.848-2.339 4.695-4.566 4.942.359.31.678.921.678 1.856 0 1.338-.012 2.419-.012 2.747 0 .268.18.58.688.482A10.019 10.019 0 0020 10.017C20 4.484 15.522 0 10 0z"
                        clipRule="evenodd"
                      />
                    </svg>
                    <span className="text-sm font-semibold leading-6">GitHub</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="relative hidden w-0 flex-1 lg:block">
          <img
            className="absolute inset-0 h-full w-full object-cover"
            src={landingImage}
            alt=""
          />
        </div>
      </div>
    </>
  )
}
