import {
    CalendarIcon,
    DocumentDuplicateIcon,
    FolderIcon,
    HomeIcon,
} from '@heroicons/react/24/outline'

const navigation = [
    { name: 'Dashboard', href: '/dashboard', icon: HomeIcon, current: true },
    { name: 'Profile', href: '/edit-profile', icon: FolderIcon, current: false },
    { name: 'Billing', href: '/billing', icon: CalendarIcon, current: false },
    { name: 'History', href: '/history', icon: DocumentDuplicateIcon, current: false },
]

// Just export the navigation
export default navigation
