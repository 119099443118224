import { PaperClipIcon } from '@heroicons/react/20/solid'
import React, { useState, useEffect } from 'react';
import ReactMarkdown from 'react-markdown';
import { useLocation } from 'react-router-dom';

export default function EditingCard() {
    // retrieve username from local storage
    const location = useLocation();

    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const queryParams = new URLSearchParams(location.search);
    const uuid = queryParams.get('uuid'); // UUID

    const [essayData, setEssayData] = useState({
        "dialogue": "",
        "dialogue_history": "",
        "draft": "You haven't finish this part yet",
        "outline": "You haven't finish this part yet",
        "prompt": "",
        "university": "",
    });

    const formatText = (text) => {
        // Use a regular expression to split the text at each occurrence of "College:" or "Student:"
        const segments = text.split(/(College:|Student:)/);
    
        // Insert a new line before and after each "College:" and "Student:"
        return segments.map(segment => {
            if (segment === 'College:' || segment === 'Student:') {
                return '\n' + segment + '\n';
            } else {
                return segment;
            }
        }).join('');
    };
    

    const retrieveEssay = async () => {
        setIsLoading(true);
        setError(null);

        try {
            const data = {
                "brainstorm_id": uuid
            }
            const response = await fetch("https://bestucollege.com/api/history/retrieve_history_details", {
                method: "POST",
                headers: {
                    "Authorization": "Bearer " + localStorage.getItem("token"),
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(data)
            });

            if (!response.ok) {
                throw new Error("HTTP request failed");
            }

            const result = await response.json();
            console.log(result);
            setEssayData(result)
            // navigate(`/brainstorm?uuid=${result["brainstorm_session_id"]}`);
        } catch (err) {
            setError(err.message);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        retrieveEssay();
    }, []);

    return (
        <div className="overflow-hidden bg-white shadow sm:rounded-lg">
            <div className="px-4 py-6 sm:px-6">
                <h3 className="text-base font-semibold leading-7 text-gray-900">{essayData.prompt}</h3>
                <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-500">{essayData.university}</p>
            </div>
            <div className="border-t border-gray-100">
                <dl className="divide-y divide-gray-100">
                    <div className="px-4 py-6 sm:grid sm:grid-cols-4 sm:gap-4 sm:px-6">
                        <dt className="text-sm font-medium text-gray-900 sm:col-span-1">Outline</dt>
                        <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-3 sm:mt-0">
                            <ReactMarkdown>{essayData.outline}</ReactMarkdown>
                        </dd>
                    </div>

                    <div className="px-4 py-6 sm:grid sm:grid-cols-4 sm:gap-4 sm:px-6">
                        <dt className="text-sm font-medium text-gray-900 sm:col-span-1">Draft</dt>
                        <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-3 sm:mt-0">
                            <ReactMarkdown>{essayData.draft}</ReactMarkdown>
                        </dd>
                    </div>

                    <div className="px-4 py-6 sm:grid sm:grid-cols-4 sm:gap-4 sm:px-6">
                        <dt className="text-sm font-medium text-gray-900 sm:col-span-1">Dialogue Summary</dt>
                        <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-3 sm:mt-0">
                            <ReactMarkdown>{essayData.dialogue}</ReactMarkdown>
                        </dd>
                    </div>

                    <div className="px-4 py-6 sm:grid sm:grid-cols-4 sm:gap-4 sm:px-6">
                        <dt className="text-sm font-medium text-gray-900 sm:col-span-1">Dialogue History</dt>
                        <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-3 sm:mt-0">
                            <ReactMarkdown>{formatText(essayData.dialogue_history)}</ReactMarkdown>
                        </dd>
                    </div>


                </dl>
            </div>
        </div>
    )
}

