import axios from './index';
    
export const save_program = async (program_metadata) => {
    const data = {
        program: program_metadata
    };

    const config = {
        headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        }
    }

    try {
        const response = await axios.post('dashboard/update-self-defined-program', data, config);
        window.location.href = `/dashboard`;
        alert("Success Added!");
    } catch (error) {
        // 错误处理
        alert("Network error")
    }
}

export const retrieve_program = async () => {    
    const config = {
        headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        }
    }

    const data = {}

    try {
        const response = await axios.post('dashboard/retrieve-self-defined-program', data, config);
        console.log(response.data);
        return response.data;
    } catch (error) {
        // 错误处理
        alert("Network error")
    }
}