import SideBar from "../sidebar/LightSideBar";
import Editor from "./Editor";
import Bars from "../components/Bars";
import navigation from "../sidebar/Navigator";

const steps = [
    { id: '01', name: 'Edit', description: 'Engage with our AI tutor to edit your essay ideas and themes. ', href: '#', status: 'current' },
    { id: '02', name: 'New Draft', description: 'Transform your brainstormed ideas into a structured outline.', href: '/outline', status: 'upcoming' },
]

export default function EditorWrapper() {
    const main_content = (
        <div>
            <Bars steps={steps}/>
            <Editor class="flex-grow"/>
        </div>
    )

    return (
        <SideBar main_content={main_content} navigation={navigation}/>
    )
}
