import { PhotoIcon, UserCircleIcon } from '@heroicons/react/24/solid'
import LightSideBar from '../sidebar/LightSideBar'
import SideBar from '../sidebar/LightSideBar'
import React, { useState } from 'react';
import { useRef, useEffect } from 'react';
import { Dialog } from '@headlessui/react';
import { useNavigate } from "react-router-dom";
import navigation from '../sidebar/Navigator';

export default function EditProfile() {

  for (var i = 0; i < navigation.length; i++) {
    console.log(navigation[i]["name"]);
    if (navigation[i]["name"] == "Profile") {
      navigation[i]["current"] = true;
    } else {
      navigation[i]["current"] = false;
    }
  }

  const navigate = useNavigate(); // 创建 navigate 函数

  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const [activities, setActivities] = useState([]);
  const endOfListRef = useRef(null);

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const addActivity = (event) => {
    event.preventDefault();
    const newActivity = {
      name: '',
      details: ''
    };
    setActivities([...activities, newActivity]);
    endOfListRef.current?.scrollIntoView({ behavior: 'smooth' });
  };


  const uploadActivities = async (activities) => {
    setIsLoading(true);
    setError(null);

    try {
      const data = {
        "activity": activities
      };

      const response = await fetch("https://bestucollege.com/api/user_auth/update-activity", {
        method: "POST",
        headers: {
          "Authorization": "Bearer " + localStorage.getItem("token"),
          "Content-Type": "application/json"
        },
        body: JSON.stringify(data)
      });

      if (!response.ok) {
        throw new Error("HTTP request failed");
      }

      // navigate(`/brainstorm?uuid=${result["brainstorm_session_id"]}`);
    } catch (err) {
      setError(err.message);
    } finally {
      setIsLoading(false);
    }
  };

  const retrieveActivity = async () => {
    setIsLoading(true);
    setError(null);

    try {
      const response = await fetch("https://bestucollege.com/api/user_auth/retrieve-activity", {
        method: "POST",
        headers: {
          "Authorization": "Bearer " + localStorage.getItem("token"),
          "Content-Type": "application/json"
        },
      });

      if (!response.ok) {
        throw new Error("HTTP request failed");
      }

      const result = await response.json();
      console.log(result);

      setActivities(result["activity"]);
      // navigate(`/brainstorm?uuid=${result["brainstorm_session_id"]}`);
    } catch (err) {
      setError(err.message);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    // Retrieve the activities from backend
    retrieveActivity();
  }, []);


  const handleActivityChange = (index, event, field) => {
    event.preventDefault();
    const newActivities = [...activities];
    newActivities[index] = {
      ...newActivities[index],
      [field]: event.target.value
    };
    setActivities(newActivities, event);
  };

  const saveActivity = async (event) => {
    console.log(123)
    event.preventDefault();
    setIsLoading(true);
    try {
      await uploadActivities(activities);
      setIsDialogOpen(true); // 显示 Dialog3
      console.log(isDialogOpen)

    } catch (error) {
      // 处理错误情况
    } finally {
      setIsLoading(false);
    }
  };

  const handleCancel = () => {
    navigate('/'); // 替换为您的 Dashboard 路径
  };

  const handleDelete = async (event, index) => {
    event.preventDefault();
    const newActivities = [...activities];
    newActivities.splice(index, 1);
    setActivities(newActivities);    
  };

  useEffect(() => {
    // Focus the first input of the last activity when a new activity is added
    if (activities.length > 0) {
      const lastActivity = document.getElementById(`activity-name-${activities.length - 1}`);
      lastActivity?.focus();
    }
  }, [activities]);

  const main_content = (
    <form>
      <div className="space-y-12">
        <div className="border-b border-gray-900/10 pb-12">
          <h2 className="text-base font-semibold leading-7 text-gray-900">Profile</h2>

          <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">

            <div className="sm:col-span-2 sm:col-start-1">
              <label htmlFor="city" className="block text-sm font-medium leading-6 text-gray-900">
                City
              </label>
              <div className="mt-2">
                <input
                  type="text"
                  name="city"
                  id="city"
                  autoComplete="address-level2"
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            <div className="col-span-full">
              <label htmlFor="cover-photo" className="block text-sm font-medium leading-6 text-gray-900">
                Profile Photo
              </label>
              <div className="mt-2 flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10">
                <div className="text-center">
                  <PhotoIcon className="mx-auto h-12 w-12 text-gray-300" aria-hidden="true" />
                  <div className="mt-4 flex text-sm leading-6 text-gray-600">
                    <label
                      htmlFor="file-upload"
                      className="relative cursor-pointer rounded-md bg-white font-semibold text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2 hover:text-indigo-500"
                    >
                      <span>Upload a file</span>
                      <input id="file-upload" name="file-upload" type="file" className="sr-only" />
                    </label>
                    <p className="pl-1">or drag and drop</p>
                  </div>
                  <p className="text-xs leading-5 text-gray-600">PNG, JPG, GIF up to 10MB</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="border-b border-gray-900/10 pb-12">
          <h2 className="text-base font-semibold leading-7 text-gray-900 p-4">Activities List</h2>
          <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
            <div className="sm:col-span-4">
              {activities.map((activity, index) => (
                <div class="flex flex-col max-w-lg mx-auto bg-white border shadow-sm rounded-xl dark:bg-gray-800 dark:border-gray-700 dark:shadow-slate-700/[.7] mb-4">
                  <div class="p-4 md:p-5">
                    <div class="flex justify-between items-center">
                      <h3 class="text-lg font-bold text-gray-800 dark:text-white">
                        🌟 Activity #{index + 1}
                      </h3>
                      {/* Small Delete button */}
                      <button
                        className="bg-red-500 hover:bg-red-700 text-white font-bold py-1 px-2 text-sm rounded"
                        onClick={(event) => handleDelete(event, index)}
                      >
                        Delete
                      </button>
                    </div>
                    <div key={index} className="mt-2"> {/* Moved key to the outermost div */}
                      <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                        <input
                          type="text"
                          value={activities[index].name}
                          onChange={(event) => handleActivityChange(index, event, 'name')}
                          className="block w-full flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                          placeholder="Activity Name"
                        />
                      </div>
                      <p class="mt-2 text-gray-800 dark:text-gray-400">
                        <div key={index} className="mt-2"> {/* Moved key to the outermost div */}
                          <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                            <textarea
                              type="text"
                              value={activities[index].details}
                              onChange={(event) => handleActivityChange(index, event, 'details')}
                              style={{ resize: 'none', maxHeight: 'calc(1.5em * 5)' }}
                              rows="5"
                              className="block w-full flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                              placeholder="Enter Activity"
                            />
                          </div>
                        </div>
                      </p>


                    </div>
                  </div>

                </div>
              ))}
              <button onClick={addActivity} className="mt-2 px-4 py-2 text-white bg-blue-500 rounded-md">
                Add New Activity
              </button>
            </div>
          </div>

        </div>
      </div>

      <div className="mt-6 flex items-center justify-end gap-x-6">
        <button
          className="inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          onClick={saveActivity}
        >
          Save
        </button>
      </div>
    </form>
  )

  const main_content_wrapper = (
    <div className="max-w-3xl mx-auto px-4 sm:px-6 lg:px-8">
      <div className="py-10">
        <div className="max-w-3xl mx-auto">
          <div className="bg-white shadow sm:rounded-lg">
            <Dialog open={isDialogOpen} onClose={() => setIsDialogOpen(false)} className="fixed inset-0 z-10 overflow-y-auto">
              <div className="flex items-center justify-center min-h-screen">
                {/* 遮罩层，只应用背景模糊效果 */}
                <div className="fixed inset-0 bg-black bg-opacity-30 backdrop-blur-sm" aria-hidden="true"></div>

                {/* Dialog 内容，保持清晰 */}
                <div className="relative bg-white rounded max-w-md mx-auto p-6">
                  <Dialog.Title>Save Sucess!</Dialog.Title>
                  <button
                    onClick={() => {
                      setIsDialogOpen(false)
                      navigate('/dashboard'); // 替换为您的 Dashboard 路径
                    }}
                    className="mt-4 w-full bg-blue-500 text-white rounded-md px-4 py-2"
                  >
                    Comfirm
                  </button>
                </div>
              </div>
            </Dialog>

            <div className="px-4 py-5 sm:p-6">{main_content}</div>
          </div>
        </div>
      </div>
    </div>
  )
  return (
    <SideBar main_content={main_content_wrapper} navigation={navigation}>
    </SideBar>
  )
}
