import React, { useState, useEffect } from 'react';
import 'react-quill/dist/quill.snow.css'; // Import styles
import './Editor.css'; // <--- Your styles here
import Suggestions from './components/Suggestion';
import Tiptap from './components/TipTap';
import Popup from './components/Popup';
import { useLocation, useNavigate } from 'react-router-dom';


const editorContent_sim = [
  {
    title: "🌟 Improve Clarity",
    feedback: "Our feedback system will help you develop your ideas and improve the clarity of your essay.",
    editing: "this is great",
    brainstorm_questions: "this is great",
    quote: "dominated by isolation and global unease"
  },
];

const Editor = () => {
  const [editorContent, setEditorContent] = useState('<p></p><p>Type or paste your text here 🌟: </p>');
  const [responseContent, setResponseContent] = useState(editorContent_sim);
  const [gradeContent, setGradeContent] = useState(null);
  const [popContent, setPopContent] = useState(false);
  const [currPos, setCurrPos] = useState(0);
  const [loader, setLoader] = useState(false);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const uuid = queryParams.get('uuid'); // UUID

  const navigate = useNavigate();

  const highlight = (keyword) => {
    var highlighted = editorContent.replace(/<mark>|<\/mark>/gi, ''); // Remove original highlights
    // Create a regex for partial matching
    // This regex will match any word that contains the keyword as a substring
    const regex = new RegExp(`(${keyword})`, 'gi');

    // Replace occurrences with <mark> tags
    highlighted = highlighted.replace(regex, `<mark>$1</mark>`);
    setEditorContent(highlighted);
  };

  const removeHighlight = () => {
    var highlighted = editorContent.replace(/<mark>|<\/mark>/gi, ''); // Remove original highlights
    setEditorContent(highlighted);
  };

  function cleanText(input) {
    // Create a virtual DOM element to host the input HTML content.
    const virtualElement = document.createElement('div');
    virtualElement.innerHTML = input;

    // Extracting the raw text, ignoring all HTML tags.
    const rawText = virtualElement.textContent;

    // Cleaning the text to keep only letters (upper and lowercase), spaces, and new lines.
    const cleanedText = rawText.replace(/[^a-zA-Z\s]+/g, '');

    // Splitting cleaned text by new lines and wrapping each line with <p></p>, forming an array of HTML strings.
    const wrappedInParagraphs = cleanedText.split('\n').map(text => text.trim());

    // Joining the array back into a single HTML string.
    const finalHtml = wrappedInParagraphs.join('');

    return finalHtml;
  }

  const getScore = async () => {
    const apiEndpoint = 'https://bestucollege.com/api/personal_statement/get_grade';
    const clearStudentResponse = cleanText(editorContent);
    const postData = {
      "uuid": uuid,
      "students_response": clearStudentResponse,
    };
  
    try {
      const response = await fetch(apiEndpoint, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
        },
        body: JSON.stringify(postData),
      });
  
      const responseData = await response.json();
      // If error field in the responseData
      if ("error" in responseData) {
        alert("Congrats! You have successfully completed the editing process for this essay. To review the changes made, please refer to the 'History' tab, where the entire editing history is documented");
      } else {
        setGradeContent(responseData);
      }
      
    } catch (error) {
      console.error('There was an error sending the request!', error);
    }
  };

  const toggleSuggestions = async () => {
    const apiEndpoint = 'https://bestucollege.com/api/personal_statement/get_feedback';
    const clearStudentResponse = cleanText(editorContent);
    const postData = {
      "uuid": uuid,
      "students_response": clearStudentResponse,
    };
  
    try {
      const response = await fetch(apiEndpoint, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
        },
        body: JSON.stringify(postData),
      });
  
      const responseData = await response.json();

      if ("error" in responseData) {
        alert("Congrats! You have successfully completed the editing process for this essay. To review the changes made, please refer to the 'History' tab, where the entire editing history is documented");
      } else {
        setResponseContent(responseData);
      }
      
    } catch (error) {
      
      console.error('There was an error sending the request!', error);
    }
  };
  

  const handleGradeAndSuggestions = async (e) => {
    e.preventDefault(); // Prevents the default action of the form
    setLoader(true);
  
    try {
      await Promise.all([getScore(), toggleSuggestions()]);
    } catch (error) {
    
      console.error('There was an error with one of the requests', error);
    } finally {
      setLoader(false);
    }
  };

  const handleNewDraft = async (e) => {
    navigate(`/new_draft?uuid=${uuid}`);
  }
  

  const closeWindow = () => {
    // Logic for closing the window
  };

  function truncateFeedback(feedback, maxWords) {
    const words = feedback.split(' ');

    if (words.length > maxWords) {
      return words.slice(0, maxWords).join(' ') + '…';
    }

    return feedback;
  }


  const handleHover = (index) => {
    console.log(`Hovering over item: ${index}`);
    highlight(responseContent[index]["quote"]);
  };

  const handleLeave = () => {
    console.log('No longer hovering over any item');
    removeHighlight();
  };


  const grade_total = (gradeContent == null) ? "Grade Total: N/A" : `Grade Total: ${gradeContent["grade_total"]}`;
  const approach_score = (gradeContent == null) ? "Approach Score: N/A" : `Approach Score: ${gradeContent["approach_score"]}`;
  const essay_trait_score = (gradeContent == null) ? "Essay Trait Score: N/A" : `Essay Trait Score: ${gradeContent["essay_trait_score"]}`;
  const general_clarity_score = (gradeContent == null) ? "General Clarity Score: N/A" : `General Clarity Score: ${gradeContent["general_clarity_score"]}`;

  const approach_feedback = (gradeContent == null) ? "N/A" : `${gradeContent["approach_feedback"]}`;
  const essay_trait_feedback = (gradeContent == null) ? "N/A" : `${gradeContent["essay_trait_feedback"]}`;
  const general_clarity_feedback = (gradeContent == null) ? "N/A" : `${gradeContent["general_clarity_feedback"]}`;

  return (
    <div className="editor-container">
      <div className="window" id="customWindow">
        <button className="exit-button" onClick={closeWindow}>X</button>
        <p>This is a window.</p>
      </div>

      <div className="editor-main">
        <div className="editor-title" contentEditable={true}>Untitled Document</div>
        {loader ? (
          <div>
            <div className="loader mb-4"></div>
            <p className="text-lg">Deep Analyzing your essay (around 2-3 minutes). Please don't close the tab. </p>
          </div>
        ) : (
          <Tiptap setEditorContent={setEditorContent} text={editorContent} />
        )}
      </div>


      <div className="editor-two">
        <div className="editor-sidebar" id="suggestions_panel">
          {responseContent ? ( // Check if editorContent is not null
            responseContent.map((item, index) => {
              const { title, feedback } = item; // Extract only the properties you need
              return (
                <Suggestions
                  item_index={index} // consider using a unique value from 'item' if available
                  title={title}
                  feedback={truncateFeedback(feedback, 25)} // limit to 25 words
                  setTrigger={setPopContent}
                  currPos={currPos}
                  setCurrPos={setCurrPos}
                  onHover={handleHover}
                  onLeave={handleLeave}
                />
              );
            })
          ) : (
            <Suggestions
              // consider using a unique value from 'item' if available
              title="❗️Feedback Catalogue"
              feedback="This area will provide student with personalized feedback according to their essay" // limit to 25 words
              item_index="0"
              setTrigger={setPopContent}
              currPos={0}
              setCurrPos={setCurrPos}
              onHover={handleHover}
              onLeave={handleLeave}
            />
          )}
        </div>

        <div className="editor-sidebar_2">
          <button className="editor-sidebar_button" onClick={handleGradeAndSuggestions}>Receive Suggestions</button>
          <button className="editor-sidebar_button" onClick={handleNewDraft}>Get New Draft</button>


          <div class="flex w-full items-center justify-center bg-[#1b2042] px-4 rounded-lg rounded_class mb-4 shadow">
            <div class="flex w-[200px] max-w-3xl flex-col rounded bg-[#1b2042] py-12 px-6 text-slate-200 rounded-lg rounded_class shadow">
              <h2 class="mb-2 font-serif text-xl">{grade_total}</h2>
              <div class="mb-2 flex gap-2">
                <span class="mb-2 h-[15px] flex-1 rounded-xl bg-blue-600"></span>
                <span class="mb-2 h-[15px] flex-1 rounded-xl bg-blue-500"></span>
                <span class="mb-2 h-[15px] flex-1 rounded-xl bg-blue-400"></span>
                <span class="mb-2 h-[15px] flex-1 rounded-xl bg-blue-300"></span>
                <span class="mb-2 h-[15px] flex-1 rounded-xl bg-blue-200"></span>
                <span class="mb-2 h-[15px] flex-1 rounded-xl bg-green-200"></span>
                <span class="mb-2 h-[15px] flex-1 rounded-xl bg-gray-100"></span>
                <span class="mb-2 h-[15px] flex-1 rounded-xl bg-gray-50"></span>
                <span class="mb-2 h-[15px] flex-1 rounded-xl bg-gray-900"></span>
              </div>
            </div>
          </div>

          <Suggestions
            item_index="0"
            title={approach_score}
            feedback={approach_feedback} // limit to 25 words
            setTrigger={setPopContent}
            currPos={currPos}
            setCurrPos={setCurrPos}
            onHover={handleHover}
            onLeave={handleLeave}
          />

          <Suggestions
            item_index="0"
            title={general_clarity_score}
            feedback={general_clarity_feedback} // limit to 25 words
            setTrigger={setPopContent}
            currPos={currPos}
            setCurrPos={setCurrPos}
            onHover={handleHover}
            onLeave={handleLeave}
          />

          <Suggestions
            item_index="0"
            title={essay_trait_score}
            feedback={essay_trait_feedback} // limit to 25 words
            setTrigger={setPopContent}
            currPos={currPos}
            setCurrPos={setCurrPos}
            onHover={handleHover}
            onLeave={handleLeave}
          />


          {/* Other sections */}
        </div>
      </div>

      <Popup trigger={popContent} setTrigger={setPopContent} currPos={currPos} setCurrPos={setCurrPos} responseContent={responseContent}>
        <h3>My popup</h3>
      </Popup>
    </div>
  );
};

export default Editor;

