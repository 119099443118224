import { Fragment, useRef, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { CheckIcon } from '@heroicons/react/24/outline'
import { useNavigate } from 'react-router-dom';
import { retrieve_program, save_program } from '../../request/dashboard_api';

export default function Popup(props) {
    const navigate = useNavigate();
    const cancelButtonRef = useRef(null)

    const [prompts, setPrompts] = useState(['Prompt #1']);

    const addNewPrompt = (event) => {
        event.preventDefault();
        const newPromptNumber = prompts.length + 1;
        setPrompts([...prompts, `Prompt #${newPromptNumber}`]);
    };

    const handleClick = async () => {
        const program_name = document.getElementById("program-name").value;
        console.log(program_name);

        const data = {
            name: program_name,
            imageUrl: "./cuilu.jpg",
            meta_data: { acceptance_rate: "100%", status: "HIGH" },
            prompts: []
        };

        for (var i = 0; i < prompts.length; i++) {
            const prompt = document.getElementById(`prompt-${i}`).value;
            data.prompts.push({
                id: i,
                name: prompt
            });
        }

        console.log(data);
        await save_program(data);
    }

    return (
        <Transition.Root show={props.open} as={Fragment}>
            <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={props.setOpen}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                                <div>
                                    <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
                                        <img className="w-17" src="./cuilu.jpg" alt='cuilu'></img>
                                    </div>

                                </div>

                                <div class="flex justify-center">

                                    <div class="w-full max-w-xs">
                                        <form class="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
                                            <div class="mb-4">
                                                <label class="block text-gray-700 text-sm font-bold mb-2" for="program-name">
                                                    Program Name
                                                </label>
                                                <input class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="program-name" type="text" placeholder="Program Name" />
                                            </div>

                                            <div class="mb-4">
                                                <label class="block text-gray-700 text-sm font-bold mb-2" for="program-name">
                                                Let's add those essay prompts, and don't forget to include the word limits at the end of each one.
                                                </label>
                                            </div>

                                            {prompts.map((prompt, index) => (
                                                <div key={index} className="mb-6">
                                                    <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor={`prompt-${index}`}>
                                                        {prompt}
                                                    </label>
                                                    <input
                                                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                                        id={`prompt-${index}`}
                                                        type="text"
                                                        placeholder={prompt}
                                                    />
                                                </div>
                                            ))}
                                            <div class="flex items-center justify-center">
                                                <button class="inline-block align-baseline font-bold text-sm text-blue-500 hover:text-blue-800"
                                                    onClick={addNewPrompt}
                                                    href="#">
                                                    Add New Prompt 
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                                <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                                    <button
                                        type="button"
                                        className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm  ring-1 ring-inset ring-gray-300 hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:col-start-2"
                                        onClick={() => {
                                            props.setOpen(false)
                                            handleClick()
                                        }
                                        }
                                    >
                                        Add to Your Dashboard
                                    </button>
                                    <button
                                        type="button"
                                        className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm  hover:bg-gray-50 sm:col-start-1 sm:mt-0"
                                        onClick={() =>
                                            props.setOpen(false)
                                        }
                                        ref={cancelButtonRef}
                                    >
                                        Cancel
                                    </button>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}
