// Note: This is the Dashboard component
import SideBar from "../sidebar/LightSideBar"
import navigation from "../sidebar/Navigator"
import EssayList from "./components/EssayList";
import ComboList from "../dashboard/components/ComboList";
import React, { useState } from 'react'

export default function HistoryList() {


    for (var i = 0; i < navigation.length; i++) {
        console.log(navigation[i]["name"]);
        if (navigation[i]["name"] == "History") {
            navigation[i]["current"] = true;
        } else {
            navigation[i]["current"] = false;
        }
    }

    const main_content = (
        <main className="bg-white min-h-screen p-6">
            <div className="p-6">
                <p className="text-2xl font-semibold leading-10 text-gray-900">Your Essay History</p>
            </div>
            
            <div className="p-4"><EssayList /></div>
        </main>
    )

    return (
        <SideBar main_content={main_content} navigation={navigation} />
    )
}

