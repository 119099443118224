import { Fragment } from 'react'
import { Menu, Transition } from '@headlessui/react'
import { EllipsisVerticalIcon } from '@heroicons/react/20/solid'
import ComboList from '../../dashboard/components/ComboList'
import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import { options } from "../../dashboard/components/SchoolInfo";

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function EssayList() {
    const [essayList, setEssayList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [current, setCurrent] = useState({
        "name": "All"
    });
    
    options.push({
        "id": options.length + 1,
        "name": "All"
    })

    const navigate = useNavigate();

    const responseToJSON = (response) => {
        console.log(response);
        var result = [];
        for (var i = 0; i < response["brainstorm_metadata"].length; i++) {
            // If response["brainstorm_meta"] includes the editing_id field, then it is a draft
            if (response["brainstorm_metadata"][i]["editing_id"] != null) {
                result.push({
                    "name": response["brainstorm_metadata"][i]["prompt"],
                    "href": `/editing-details?uuid=${response["brainstorm_metadata"][i]["editing_id"]}`,
                    "university": response["brainstorm_metadata"][i]["university"],
                    "dueDate": response["brainstorm_metadata"][i]["created_at"],
                    "type": response["brainstorm_metadata"][i]["type"],
                });
            } else {
                result.push({
                    "name": response["brainstorm_metadata"][i]["prompt"],
                    "href": `/brainstorm-details?uuid=${response["brainstorm_metadata"][i]["brainstorm_id"]}`,
                    "university": response["brainstorm_metadata"][i]["university"],
                    "dueDate": response["brainstorm_metadata"][i]["created_at"],
                    "type": response["brainstorm_metadata"][i]["type"],
                });
            }
        }
        
        return result.reverse();
    }

    const retrieveEssay = async () => {
        console.log(current);
        var school;

        setIsLoading(true);
        setError(null);


        if (current !== null) {
            school = current["name"];
        } else {
            school = "All";
        }
        

        try {
            const data = {
                "university": school
            }
            const response = await fetch("https://bestucollege.com/api/history/retrieve_history", {
                method: "POST",
                headers: {
                    "Authorization": "Bearer " + localStorage.getItem("token"),
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(data)
            });

            if (!response.ok) {
                throw new Error("HTTP request failed");
            }

            const result = await response.json();
            console.log(responseToJSON(result));
            setEssayList(responseToJSON(result));
            // navigate(`/brainstorm?uuid=${result["brainstorm_session_id"]}`);
        } catch (err) {
            setError(err.message);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        retrieveEssay();
    }, [current]);

    return (
        <ul role="list" className="divide-y divide-gray-100">
            <ComboList people={options} setNewVariable={setCurrent} />
            {essayList.map((project) => (
                <li key={project.id} className="flex items-center justify-between gap-x-6 py-5">
                    <div className="min-w-0">
                        <div className="flex items-start gap-x-3">
                            <p className="text-sm font-semibold leading-6 text-gray-900">{project.university} - {project.name} - [{project.type}]</p>
                        </div>
                        <div className="mt-1 flex items-center gap-x-2 text-xs leading-5 text-gray-500">
                            <p className="whitespace-nowrap">
                                Finished on <time dateTime={project.dueDateTime}>{project.dueDate}</time>
                            </p>
                            <svg viewBox="0 0 2 2" className="h-0.5 w-0.5 fill-current">
                                <circle cx={1} cy={1} r={1} />
                            </svg>
                            <p className="truncate">{project.university}</p>
                        </div>
                    </div>
                    <div className="flex flex-none items-center gap-x-4">
                        <a
                            href={project.href}
                            className="hidden rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:block"
                        >
                            View project<span className="sr-only">, {project.name}</span>
                        </a>
                        <Menu as="div" className="relative flex-none">
                            <Menu.Button className="-m-2.5 block p-2.5 text-gray-500 hover:text-gray-900">
                                <span className="sr-only">Open options</span>
                                <EllipsisVerticalIcon className="h-5 w-5" aria-hidden="true" />
                            </Menu.Button>
                            <Transition
                                as={Fragment}
                                enter="transition ease-out duration-100"
                                enterFrom="transform opacity-0 scale-95"
                                enterTo="transform opacity-100 scale-100"
                                leave="transition ease-in duration-75"
                                leaveFrom="transform opacity-100 scale-100"
                                leaveTo="transform opacity-0 scale-95"
                            >
                                <Menu.Items className="absolute right-0 z-10 mt-2 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                                    <Menu.Item>
                                        {({ active }) => (
                                            <a
                                                href="#"
                                                className={classNames(
                                                    active ? 'bg-gray-50' : '',
                                                    'block px-3 py-1 text-sm leading-6 text-gray-900'
                                                )}
                                            >
                                                Edit<span className="sr-only">, {project.name}</span>
                                            </a>
                                        )}
                                    </Menu.Item>
                                    <Menu.Item>
                                        {({ active }) => (
                                            <a
                                                href="#"
                                                className={classNames(
                                                    active ? 'bg-gray-50' : '',
                                                    'block px-3 py-1 text-sm leading-6 text-gray-900'
                                                )}
                                            >
                                                Move<span className="sr-only">, {project.name}</span>
                                            </a>
                                        )}
                                    </Menu.Item>
                                    <Menu.Item>
                                        {({ active }) => (
                                            <a
                                                href="#"
                                                className={classNames(
                                                    active ? 'bg-gray-50' : '',
                                                    'block px-3 py-1 text-sm leading-6 text-gray-900'
                                                )}
                                            >
                                                Delete<span className="sr-only">, {project.name}</span>
                                            </a>
                                        )}
                                    </Menu.Item>
                                </Menu.Items>
                            </Transition>
                        </Menu>
                    </div>
                </li>
            ))}
        </ul>
    )
}
