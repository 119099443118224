// Note: This is the Dashboard component
import SideBar from "../sidebar/LightSideBar"
import SchoolList from "./components/SchoolList"
import navigation from "../sidebar/Navigator"

export default function Dashboard() {
    // retrieve username from local storage
    const username = localStorage.getItem('username');

    for (var i = 0; i < navigation.length; i++) {
        console.log(navigation[i]["name"]);
        if (navigation[i]["name"] == "Dashboard") {
            navigation[i]["current"] = true;
        } else {
            navigation[i]["current"] = false;
        }
    }

    const main_content = (
        <main className="bg-white min-h-screen">
            <div className="p-6">
                {
                    localStorage.getItem('token') ?
                    <p className="text-2xl font-semibold leading-10 text-gray-900">Welcome Back, {username}!</p> :
                    <p className="text-2xl font-semibold leading-10 text-gray-900">Welcome! Please Register or Log In.</p>
                }
            </div>
            <div className="p-4">{localStorage.getItem('token') && <SchoolList />}</div>
        </main>
    )

    return (
        <SideBar main_content={main_content} navigation={navigation} />
    )
}

