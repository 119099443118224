import ComboList from "./ComboList";
import React, { useState, useEffect } from 'react'
import { options } from './SchoolInfo'
import Popup from "./Popup";

export default function NewProject({ school_list }) {
  const [newUniversity, setNewUniveristy] = useState("");
  const [error, setError] = useState(null);
  const [load, setLoading] = useState(true);
  const [open, setOpen] = useState(false);

  const handleClick = (event) => {
    event.preventDefault();
    setOpen(true);
  }


  async function AddNewUniversity() {
    try {
      if (newUniversity == null) {
        alert("Please select a school");
      }
      else {
        let merged_list = []
        if (school_list != []) {
          merged_list = [...new Set([...school_list["school_list"], ...[newUniversity["name"]]])];
        } else {
          merged_list = [newUniversity["name"]];
        }
        const data = {
          "school_list": merged_list
        };

        const response = await fetch("https://bestucollege.com/api/dashboard/update_school_list", {
          method: "POST",
          headers: {
            "Authorization": "Bearer " + localStorage.getItem("token"),
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data)
        });

        if (!response.ok) {
          alert("HTTP request failed");
          alert(localStorage.getItem("token"))
        }

        window.location.reload();
      }

    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    console.log('university', newUniversity);
    console.log('school_list', school_list);
  }, [newUniversity]);

  return (
    <button
      type="button"
      className="relative block w-full rounded-lg border-2 border-dashed border-gray-300 p-12 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
    >
      <svg
        className="mx-auto h-12 w-12 text-gray-400"
        stroke="currentColor"
        fill="none"
        viewBox="0 0 48 48"
        aria-hidden="true"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M8 14v20c0 4.418 7.163 8 16 8 1.381 0 2.721-.087 4-.252M8 14c0 4.418 7.163 8 16 8s16-3.582 16-8M8 14c0-4.418 7.163-8 16-8s16 3.582 16 8m0 0v14m0-4c0 4.418-7.163 8-16 8S8 28.418 8 24m32 10v6m0 0v6m0-6h6m-6 0h-6"
        />
      </svg>
      <span className="mt-2 block text-sm font-semibold text-gray-900">Add New School</span>
      <ComboList people={options} setNewVariable={setNewUniveristy} />
      <div className="mt-6 flex flex-col md:flex-row md:space-x-4">
        <button
          className="inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 mb-4 md:mb-0"
          onClick={AddNewUniversity}
        >
          New Project
        </button>
        <Popup open={open} setOpen={setOpen} />
        <button
          className="inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          onClick={handleClick}
        >
          Not Found? Add New Program
        </button>
      </div>


    </button>
  )
}
