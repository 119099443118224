import { Fragment } from 'react'
import { Menu, Transition } from '@headlessui/react'
import { EllipsisHorizontalIcon } from '@heroicons/react/20/solid'
import ComboList from './ComboList'
import NewProject from './NewProject'
import { useNavigate } from 'react-router-dom';
import { school_data } from './SchoolInfo'
import React, { useEffect, useState } from 'react'
import { retrieve_program, save_program } from '../../request/dashboard_api';

const statuses = {
  HIGH: 'text-green-700 bg-green-50 ring-green-600/20',
  MEDIUM: 'text-gray-600 bg-gray-50 ring-gray-500/10',
  LOW: 'text-red-700 bg-red-50 ring-red-600/10',
}


const options = [
    { id: 1, name: 'Harvard has long recognized the importance of enrolling a diverse student body. How will the life experiences that shape who you are today enable you to contribute to Harvard?* (200 words)' },
    { id: 2, name: 'Briefly describe an intellectual experience that was important to you.* (200 words)' },
    { id: 3, name: 'Briefly describe any of your extracurricular activities, employment experience, travel, or family responsibilities that have shaped who you are.* (200 words)' },
    { id: 4, name: 'How do you hope to use your Harvard education in the future?* (200 words)' },
    // More users...
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function SchoolList() {
   // Make default load use Side Effect

   const [schoolList, setSchoolList] = useState([]);
   const [isLoading, setIsLoading] = useState(false);
   const [error, setError] = useState(null);
   const [school_data2, setSchoolData] = useState([]);
   const [prompt, setPrompt] = useState(null);
   const [updateTrigger, setUpdateTrigger] = useState(false); // For the update on the school list

    const navigate = useNavigate();

    const [activity, setActivities] = useState([]);

    const retrieveActivity = async () => {
      setIsLoading(true);
      setError(null);
  
      try {
          const response = await fetch("https://bestucollege.com/api/user_auth/retrieve-activity", {
              method: "POST",
              headers: {
                  "Authorization": "Bearer " + localStorage.getItem("token"),
                  "Content-Type": "application/json"
              },
          });
  
          if (!response.ok) {
              throw new Error("HTTP request failed");
          }
  
          const result = await response.json();
          console.log(result);
  
          setActivities(result["activity"]);
          // navigate(`/brainstorm?uuid=${result["brainstorm_session_id"]}`);
      } catch (err) {
          setError(err.message);
      } finally {
          setIsLoading(false);
      }
    };

    const handleClick = async (university_name) => {
       if (prompt == null) {
          alert("Please select a prompt");
       } else {
        console.log(activity);
        if (activity.length == 0) {
          alert("Please add at least one activity in the profile section")
        } else {
          navigate(`/instruction/?university=${university_name}&prompt=${prompt["name"]}&word_limit=${prompt["word_limit"]}`);
        }
       }
    }

    const handleClickEditor = async (university_name) => {
      if (prompt == null) {
        alert("Please select a prompt");
      } else {
        navigate(`/instruction-editing/?university=${university_name}&prompt=${prompt["name"]}&word_limit=${prompt["word_limit"]}`);
      }
   }

   async function updateSchool(school_list) {
    try {
        const data = {
          "school_list": school_list
        };

        const response = await fetch("https://bestucollege.com/api/dashboard/update_school_list", {
          method: "POST",
          headers: {
            "Authorization": "Bearer " + localStorage.getItem("token"),
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data)
        });
        //window.location.reload();
    } catch (error) {
      setError(error.message);
    } finally {
      setIsLoading(false);
    }
  }

    const deleteSchool = async (university_name) => {
      console.log(schoolList);
      console.log(university_name);
      var newSchoolList = {};
      // Check if the school is in the list
      if (schoolList["school_list"].includes(university_name)) {
        // drop that school from the list
        newSchoolList = schoolList["school_list"].filter((school) => school != university_name);
        await updateSchool(newSchoolList);
      } else {
        alert("We temporarily cannot delete this program. Please try again later." );
      }
      
      window.location.reload();
    }
 
   useEffect(() => {
    const fetchSchoolList = async () => {
        setIsLoading(true);
        setError(null);
    
        try {
            const response = await fetch("https://bestucollege.com/api/dashboard/retrieve_school_list", {
                method: "GET",
                headers: {
                    "Authorization": "Bearer " + localStorage.getItem("token"),
                },
            });
    
            if (!response.ok) {
                throw new Error("HTTP request failed");
            }
    
            const result = await response.json();
            setSchoolList(result); // Assuming result is the list you need
    
            let filteredSchoolData = [];
            let count = 0;
    
            if ("school_list" in result) {
                for (let i = 0; i < school_data.length; i++) {
                    if (result.school_list.includes(school_data[i].name)) {
                        const newData = { ...school_data[i], id: count++ };
                        filteredSchoolData.push(newData);
                    }
                }
            }


            const program_info = await retrieve_program();

            if (program_info != null) {
              for (let i = school_data.length; i < school_data.length + program_info.length; i++) {
                const newData = { ...program_info[i - school_data.length], id: count++ };
                filteredSchoolData.push(newData);
              }
              filteredSchoolData.concat(program_info);
            }

            setSchoolData(filteredSchoolData);
        } catch (err) {
            setError(err.message);
        } finally {
            setIsLoading(false);
        }
    };
 
    fetchSchoolList();
    retrieveActivity();
    
   }, []); // The empty array makes this effect run only on mount

  return (
    <ul role="list" className="grid grid-cols-1 gap-x-6 gap-y-8 lg:grid-cols-3 xl:gap-x-8">
      {school_data2 ? school_data2.map((client) => (
        <li key={client.id} className="rounded-xl border border-gray-200">
          <div className="flex items-center gap-x-4 border-b border-gray-900/5 bg-gray-50 p-6">
            <img
              src={client.imageUrl}
              alt={client.name}
              className="h-12 w-12 flex-none rounded-lg bg-white object-cover ring-1 ring-gray-900/10"
            />
            <div className="text-sm font-medium leading-6 text-gray-900">{client.name}</div>
            <Menu as="div" className="relative ml-auto">
              <Menu.Button className="-m-2.5 block p-2.5 text-gray-400 hover:text-gray-500">
                <span className="sr-only">Open options</span>
                <EllipsisHorizontalIcon className="h-5 w-5" aria-hidden="true" />
              </Menu.Button>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="absolute right-0 z-10 mt-0.5 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                  <Menu.Item>
                    {({ active }) => (
                      <button
                        className={classNames(
                          active ? 'bg-gray-50' : '',
                          'block px-3 py-1 text-sm leading-6 text-gray-900'
                        )}
                        onClick={() => deleteSchool(client.name)}
                      >
                        Delete<span className="sr-only">, {client.name}</span>
                      </button>
                    )}
                  </Menu.Item>
                </Menu.Items>
              </Transition>
            </Menu>
          </div>
          <dl className="-my-3 divide-y divide-gray-100 px-6 py-4 text-sm leading-6">
            <div className="flex justify-between gap-x-4 py-3">
              <dt className="text-gray-500">Chance</dt>
              <dd className="flex items-start gap-x-2">
                <div className="font-medium text-gray-900">{client.meta_data.acceptance_rate}</div>
                <div
                  className={classNames(
                    statuses[client.meta_data.status],
                    'rounded-md py-1 px-2 text-xs font-medium ring-1 ring-inset'
                  )}
                >
                  {client.meta_data.status}
                </div>
              </dd>
            </div>
            <div className="flex justify-between gap-x-4 py-3 relative">
               <ComboList people={client.prompts} setNewVariable={setPrompt} message="Prompt"/>
            </div>
            <div>
              <div className="flex gap-x-4 py-3">
                <button
                  className="inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  onClick={() => handleClick(client.name)}
                >
                  Brainstorm
                </button>

                <button
                  className="inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  onClick={() => handleClickEditor(client.name)}
                >
                  Edit
                </button>
              </div>
            </div>
          </dl>
        </li>
      ))
      : null}
      
      <NewProject school_list={schoolList}/>
    </ul>
  )
}
