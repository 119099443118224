export const school_data = ([
  {
    id: 1,
    name: 'Princeton University',
    imageUrl: 'https://logos-world.net/wp-content/uploads/2023/02/Princeton-University-Logo.png',
    meta_data: {acceptance_rate: '6%', status: 'LOW' },
    prompts: [
        { id: 1, name: "For A.B. Degree : Applicants must write about the academic areas that most pique their curiosity and how Princeton's programs suit their interests", word_limit: "250" },
        { id: 2, name: "or B.S.E. Degree :Applicants should describe why they are interested in studying engineering at Princeton, including any experiences or exposure to engineering, and how the programs at the University suit their particular interests (250 words or fewer).", word_limit: "250" },
        { id: 3, name: "Discuss how your story intersects with Princeton's commitment to service and civic engagement (250 words or fewer).", word_limit: "250"},
        { id: 4, name: 'Reflect on how your lived experiences will impact conversations in various campus spaces and what lessons you have learned in life (500 words or fewer).', word_limit: "500" },
    ]
  },
  {
    id: 2,
    name: "MIT",
    imageUrl: "https://logos-world.net/wp-content/uploads/2021/09/MIT-Massachusetts-Institute-of-Technology-Logo.png",
    meta_data: { acceptance_rate: "6.62%", status: "LOW" },
    prompts: [
      {
        id: 1,
        name: "Activity Essay: Applicants must describe something they do simply for the pleasure of it (150 words or fewer).", word_limit: "150"
      },
      {
        id: 2,
        name: "Why Major Essay: Applicants must explain why a particular field of study at MIT appeals to them (100 words or fewer).", word_limit: "100"
      },
      {
        id: 3,
        name: "Personal Background Essay: Describe how the world you come from—including your opportunities, experiences, and challenges—has shaped your dreams and aspirations (225 words or fewer)." , word_limit: "225"
      },
      {
        id: 4,
        name: "Community Essay: Write about a collaborative experience where you sought to learn from others or contributed to your community together (225 words or fewer)." , word_limit: "225"
      },
      {
        id: 5,
        name: "Challenge Essay: Discuss how you managed a situation or challenge that you didn’t expect and what you learned from it (225 words or fewer)." , word_limit: "225"
      }
    ]
  },
  {
    id: 3,
    name: "Harvard University",
    imageUrl: "https://upload.wikimedia.org/wikipedia/commons/2/25/Harvard_University_shield.png",
    meta_data: { acceptance_rate: "3.4%", status: "LOW" },
    prompts: [
      {
        id: 1,
        name: "Diversity Essay: Describe how the life experiences that shape who you are today will enable you to contribute to Harvard (200 words)." , word_limit: "200"
      },
      {
        id: 2,
        name: "Intellectual Experience Essay: Briefly describe an intellectual experience that was important to you (200 words)." , word_limit: "200"
      },
      {
        id: 3,
        name: "Activity Essay: Explain any of your extracurricular activities, employment experience, travel, or family responsibilities that have shaped who you are (200 words)." , word_limit: "200"
      },
      {
        id: 4,
        name: "Future Aspirations Essay: Discuss how you hope to use your Harvard education in the future (200 words)." , word_limit: "200"
      },
      {
        id: 5,
        name: "Roommate Essay: Share the top three things your roommates might like to know about you (200 words)." , word_limit: "200"
      }
    ]
  },
  {
    id: 4,
    name: "Stanford University",
    imageUrl: "https://logos-world.net/wp-content/uploads/2021/10/Stanford-Logo.png",
    meta_data: { acceptance_rate: "4.34%", status: "LOW" },
    prompts: [
      {
        id: 1,
        name: "Learning Essay: Reflect on an idea or experience that makes you genuinely excited about learning (100 to 250 words)." , word_limit: "250"
      },
      {
        id: 2,
        name: "Roommate Essay: Write a note to your future roommate that reveals something about you or that will help your roommate — and us — know you better (100 to 250 words)." , word_limit: "100-250"
      },
      {
        id: 3,
        name: "Personal Contribution Essay: Describe aspects of your life experiences, interests, and character that would help you make a distinctive contribution as an undergraduate to Stanford University (100 to 250 words)." , word_limit: "100-250"
      }
    ]
  },
  {
    id: 5,
    name: "Yale University",
    imageUrl: "https://download.logo.wine/logo/Yale_University/Yale_University-Logo.wine.png",
    meta_data: { acceptance_rate: "4.4%", status: "LOW" },
    prompts: [
      {
        id: 1,
        name: "Short 1 - An explanation of a topic or idea that excites them, related to the academic areas chosen (200 words or fewer)." , word_limit: "200"
      },
      {
        id: 2,
        name: "Short 2 - A response to the question 'What is it about Yale that has led you to apply?' (125 words or fewer)." , word_limit: "125"
      },
      {
        id: 3,
        name: "Essay 1 - Reflect on a time you discussed an issue important to you with someone holding an opposing view (400 words)." , word_limit: "400"
      },
      {
        id: 4,
        name: "Essay 2 - Reflect on your membership in a community to which you feel connected (400 words)." , word_limit: "400"
      },
      {
        id: 5,
        name: "Essay 3 - Reflect on an element of your personal experience that you feel will enrich your college (400 words)." , word_limit: "400"
      }
    ]
  },
  {
    id: 6,
    name: "University of Pennsylvania",
    imageUrl: "https://logos-world.net/wp-content/uploads/2023/01/UPenn-Logo.png",
    meta_data: { acceptance_rate: "7.44%", status: "LOW" },
    prompts: [
      {
        id: 1,
        name: "Write a short thank-you note to someone you have not yet thanked and would like to acknowledge. (150-200 words)." , word_limit: "150-200"
      },
      {
        id: 2,
        name: "How will you explore community at Penn? (150-200 words)." , word_limit: "150-200"
      },
      {
        id: 3,
        name: "Wharton prepares its students to make an impact by applying business methods and economic theory to real-world problems, including economic, political, and social issues. Please reflect on a current issue of importance to you and share how you hope a Wharton education would help you to explore it. (150-200 words)." , word_limit: "150-200"
      },
      {
        id: 4,
        name: "Arts and Science: The flexible structure of The College of Arts and Sciences’ curriculum is designed to inspire exploration, foster connections, and help you create a path of study through general education courses and a major. What are you curious about, and how would you take advantage of opportunities in the arts and sciences? (150-200 words)."
  , word_limit: "250-200"
      },
      {
        id: 5,
        name: "Engineering: Penn Engineering prepares its students to become leaders in technology, by combining a strong foundation in the natural sciences and mathematics, exploration in the liberal arts, and depth of study in focused disciplinary majors. Please share how you hope to explore your engineering interests at Penn. (150-200 words)." , word_limit: "150-200"
      },
      {
        id: 6,
        name: "Nursing: Penn Nursing intends to meet the health needs of society in a global and multicultural world by preparing its students to impact healthcare by advancing science and promoting equity. What do you think this means for the future of nursing, and how do you see yourself contributing to our mission of promoting equity in healthcare? (150-200 words)." , word_limit: "150-200"
      }
    ]
  },
  {
    id: 7,
    name: "Duke University",
    imageUrl: "https://download.logo.wine/logo/Duke_University/Duke_University-Logo.wine.png",
    meta_data: { acceptance_rate: "4.8%", status: "LOW" },
    prompts: [
      {
        id: 1,
        name: "What is your sense of Duke as a university and a community, and why do you consider it a good match for you? (250-word limit)." , word_limit: "250"
      },
      {
        id: 2,
        name: "Perspective Response: Share your personal perspectives, beliefs, and lived experiences that will contribute to the Duke community (250 words)." , word_limit: "250"
      },
      {
        id: 3,
        name: "Intellectual Experience: Describe an intellectual experience in the past two years that fascinated you (250 words)." , word_limit: "250"
      },
      {
        id: 4,
        name: "Beliefs & Values: Discuss how you communicate about important beliefs and values, including agreements and disagreements (250 words)." , word_limit: "250"
      },
      {
        id: 5,
        name: "Being Different: Reflect on aspects of your identity or experiences that make you different, and what that means to you (250 words)." , word_limit: "250"
      },
      {
        id: 6,
        name: "Orientation, Identity, Expression: Share how your sexual orientation, gender identity, or gender expression has meaning for you (250 words)." , word_limit: "250"
      }
    ]
  },
  {
    id: 8,
    name: " California Institute of Technology",
    imageUrl: "https://upload.wikimedia.org/wikipedia/commons/1/18/Caltech_logo_2014.png",
    meta_data: { acceptance_rate: "3.9%", status: "LOW" },
    prompts: [
      {
        id: 1,
        name: "What is your proposed area of interest at Caltech, Why did you choose that area of interest? (200 words)." , word_limit: "200"
      },
      {
        id: 2,
        name: "At Caltech, we investigate some of the most challenging, fundamental problems in science, technology, engineering, and mathematics. Identify and describe two STEM-related experiences from your high school years, either in or out of the classroom, and tell us how and why they activated your curiosity. What about them made you want to learn more and explore further? (200 words each)." , word_limit: "200"
      },
      {
        id: 3,
        name: "The creativity, inventiveness, and innovation of Caltech’s students, faculty, and researchers have won Nobel Prizes and put rovers on Mars. But Techers also imagine smaller scale innovations every day, from new ways to design solar cells to how to 3D print dorm decor. How have you been a creator, inventor, or innovator in your own life? This question can be answered as a written response (200-250 words)." , word_limit: "200-250"
      },
      {
        id: 4,
        name: "Caltech’s mission – to cultivate learning, discovery, and innovation for the benefit of humanity – relies on its community members embracing our Mission-Based Values, which include: 1) Openness and enthusiasm for having preconceptions challenged 2) Respect and appreciation for the idea that, while we are all members of the same community, the opportunities we’ve had to develop, showcase, and apply our talents have not been equal 3) Passion for the ideal that science can and should meaningfully improve the lives of others Share what one or more of these values evokes for you.* (400 words)." , word_limit: "400"
      },
      {
        id: 5,
        name: "Optional - If there are aspects of your life or social or personal identity that you feel are not captured elsewhere in this application, please tell us about them below. (150 words)." , word_limit: "150"
      }
    ]
  },
  {
    id: 9,
    name: "Northwestern University",
    imageUrl: "https://logos-world.net/wp-content/uploads/2023/03/Northwestern-University-Logo.png",
    meta_data: { acceptance_rate: "8.9%", status: "LOW" },
    prompts: [
      {
        id: 1,
        name: "We want to be sure we’re considering your application in the context of your personal experiences: What aspects of your background, your identity, or your school, community, and/or household settings have most shaped how you see yourself engaging in Northwestern’s community, be it academically, extracurricularly, culturally, politically, socially, or otherwise? (300 words max)." , word_limit: "300"
      },
      {
        id: 2,
        name: "Optional - Painting 'The Rock' is a tradition at Northwestern that invites all forms of expression—students promote campus events or extracurricular groups, support social or activist causes, show their Wildcat spirit (what we call 'Purple Pride'), celebrate their culture, and more. What would you paint on The Rock, and why? (200 words)." , word_limit: "200"
      },
      {
        id: 3,
        name: "Optional - Northwestern fosters a distinctively interdisciplinary culture. We believe discovery and innovation thrive at the intersection of diverse ideas, perspectives, and academic interests. Within this setting, if you could dream up an undergraduate class, research project, or creative effort (a start-up, a design prototype, a performance, etc.), what would it be? Who might be some ideal classmates or collaborators? (200 words)." , word_limit: "200"
      },
      {
        id: 4,
        name: "Optional - Community and belonging matter at Northwestern. Tell us about one or more communities, networks, or student groups you see yourself connecting with on campus. (200 words)." , word_limit: "200"
      },
      {
        id: 5,
        name: "Optional - Northwestern’s location is special: on the shore of Lake Michigan, steps from downtown Evanston, just a few miles from Chicago. What aspects of our location are most compelling to you, and why? (200 words)." , word_limit: "200"
      },
      {
        id: 6,
        name: "Optional - Northwestern is a place where people with diverse backgrounds from all over the world can study, live, and talk with one another. This range of experiences and viewpoints immeasurably enriches learning. How might your individual background contribute to this diversity of perspectives in Northwestern’s classrooms and around our campus? (200 words)." , word_limit: "200"
      }
    ]
  },
  {
    id: 10,
    name: "Brown University",
    imageUrl: "https://logos-world.net/wp-content/uploads/2022/11/Brown-University-Logo.png",
    meta_data: { acceptance_rate: "5.1%", status: "LOW" },
    prompts: [
      {
        id: 1,
        name: "Brown’s Open Curriculum allows students to explore broadly while also diving deeply into their academic pursuits. Tell us about any academic interests that excite you, and how you might pursue them at Brown. (200-250 words)." , word_limit: "200-250"
      },
      {
        id: 2,
        name: "Students entering Brown often find that making their home on College Hill naturally invites reflection on where they came from. Share how an aspect of your growing up has inspired or challenged you, and what unique contributions this might allow you to make to the Brown community. (200-250 words)." , word_limit: "200-250"
      },
      {
        id: 3,
        name: "Brown students care deeply about their work and the world around them. Students find contentment, satisfaction, and meaning in daily interactions and major discoveries. Whether big or small, mundane or spectacular, tell us about something that brings you joy. (200-250 words)." , word_limit: "200-250"
      },
      {
        id: 4,
        name: "Optional (extracurricular) - What is your most meaningful extracurricular commitment, and what would you like us to know about it? (100 words)." , word_limit: "100"
      },
      {
        id: 5,
        name: "Optional (teach class) - If you could teach a class on any one thing, whether academic or otherwise, what would it be? (100 words)." , word_limit: "100"
      }
    ]
  },
  {
    id: 11,
    name: "Johns Hopkins University",
    imageUrl: "https://upload.wikimedia.org/wikipedia/en/0/09/Johns_Hopkins_University%27s_Academic_Seal.svg",
    meta_data: { acceptance_rate: "6.5%", status: "LOW" },
    prompts: [
      {
        id: 1,
        name: "Tell us about an aspect of your identity (e.g. race, gender, sexuality, religion, community, etc.) or a life experience that has shaped you as an individual and how that influenced what you’d like to pursue in college at Hopkins. (This can be a future goal or experience that is either academic, extracurricular, or social) (300 words)." , word_limit: "300 words"
      }
    ]
  },
  {
    id: 12,
    name: "Cornell University",
    imageUrl: "https://logos-world.net/wp-content/uploads/2022/01/Cornell-University-Logo.png",
    meta_data: { acceptance_rate: "7%", status: "LOW" },
    prompts: [
      {
        id: 1,
        name: "In the aftermath of the U.S. Civil War, Ezra Cornell wrote, 'I would found an institution where any person can find instruction in any study.' Explain how your life experiences will help inform your contributions to a learning community devoted to '...any person…any study.' We encourage you to think broadly about your life experiences, including how local (e.g., family, school, neighborhood) or global communities you’ve been part of have helped shape your perspective. (350 words)." , word_limit: "350 words"
      },
      {
        id: 2,
        name: "Arts & Science - At the College of Arts and Sciences, curiosity will be your guide. Discuss how your passion for learning is shaping your academic journey, and what areas of study or majors excite you and why. Your response should convey how your interests align with the College, and how you would take advantage of the opportunities and curriculum in Arts and Sciences. (650 words)." , word_limit: "650"
      },
      {
        id: 3,
        name: "Agriculture (required) - Why are you drawn to studying the major you have selected? Please discuss how your interests and related experiences have influenced your choice. How will an education from the College of Agriculture and Life Sciences (CALS) at Cornell University specifically serve to support your learning, growth, and the pursuit of your goals? (650 words)." , word_limit: "650"
      },
      {
        id: 4,
        name: "Agriculture (Optional) - At Cornell CALS, we aim to leave the world better than we found it, so we seek out those who are not simply driven to master their discipline, but who are also passionate about doing so to serve the public good. Please elaborate on an experience where you had a meaningful impact on people, a community, and/or an environment of importance to you. (200 words)." , word_limit: "200"
      },
      {
        id: 5,
        name: "Agriculture (Optional) - Cornell CALS is dedicated to purpose-driven study of the agricultural, life, environmental, and social sciences and welcomes students with interests that span a wide variety of disciplines. Given our agricultural history and commitment to educating the next generation of agriculturalists, please share if you have a background or interest in agriculture, regardless of your intended major. An 'agricultural entity' for the purpose of this question is defined as cultivating soil, growing crops, and raising livestock (e.g., farm, ranch, greenhouse, vineyard, etc.) Students select from check boxes (all that apply): A primary source of income for my parent/guardian(s) comes from ownership of or employment by an agricultural entity. My extended family owns or operates an agricultural entity. I have experience working in an agricultural entity. I have interest in pursuing a career in an agricultural entity. Then are offered the prompt 'Please feel free to share additional details below.' (100 words)." , word_limit: "100"
      },
      {
        id: 6,
        name: "Architecture - How do your interests directly connect with your intended major at the College of Architecture, Art and Planning (AAP)? Why architecture (B.Arch), art (BFA) or urban and regional studies (URS)? B.Arch applicants, please provide an example of how a creative project or passion sparks your motivation to pursue a 5-year professional degree program. BFA applicants may want to consider how they could integrate a range of interests and available resources at Cornell into a coherent art practice. URS students may want to emphasize their enthusiasm and depth of interest in the study of urban and regional issues. (650 words)." , word_limit: "650"
      },
      {
        id: 7,
        name: "Business - What kind of business student are you? Using your personal, academic, or volunteer/work experiences, describe the topics or issues that you care about and why they are important to you. Your response should convey how your interests align with the school to which you are applying within the Cornell SC Johnson College of Business (the Charles H. Dyson School of Applied Economics and Management or the Cornell Peter and Stephanie Nolan School of Hotel Administration). (650 words)." , word_limit: "650"
      },
      {
        id: 8,
        name: "Public Policy - Why are you drawn to studying public policy? Drawing on your experiences, tell us about why you are interested in your chosen major and how attending the Brooks School will help you achieve your life goals. (650 words)." , word_limit: "650"
      },
      {
        id: 9,
        name: "Engineering (required) - How do your interests directly connect with Cornell Engineering? If you have an intended major, what draws you to that department at Cornell Engineering? If you are unsure what specific engineering field you would like to study, describe how your general interest in engineering most directly connects with Cornell Engineering. It may be helpful to concentrate on one or two things that you are most excited about. (250 words)." , word_limit: "250"
  },
  { id: 10, name: "Engineering (A) - Describe an engineering problem that impacts your local community. This could be your school, neighborhood, town, region, or a group you identify with. Describe one to three things you might do as an engineer to solve the problem (250 words)." , word_limit: "250"
  },
  { id: 11, name: "Engineering (B) - Diversity in all forms is intrinsic to excellence in engineering. Engineering the best solutions to complex problems is often achieved by drawing from the diverse ingenuity of people from different backgrounds, lived experiences, and identities. How do you see yourself contributing to the diversity and/or the inclusion of the Cornell Engineering community? What is the unique voice you would bring to the Cornell Engineering community? (250 words)." , word_limit: "250" } ,
  { id: 12, name: "Ecology - How have your related experiences influenced your decision to apply to the College of Human Ecology (CHE)? How will your choice of major impact your goals and plans for the future? Your response should show us that your interests and aspirations align with CHE and your choice of major. (650 words)." , word_limit: "650" } ,
  { id: 13, name: "Industrial and Labor Relations - Using your personal, academic, or volunteer/work experiences, describe the topics or issues that you care about and why they are important to you. Your response should show us that your interests align with the ILR School. (650 words)." , word_limit: "650" } 
   ]
  },
  {
    id: 13,
    name: "University of Chicago",
    imageUrl: "https://biocars.uchicago.edu/wp-content/uploads/2019/05/cropped-logo.png",
    meta_data: { acceptance_rate: "5.92%", status: "LOW" },
    prompts: [
      {
        id: 1,
        name: "(Required) How does the University of Chicago, as you know it now, satisfy your desire for a particular kind of learning, community, and future? Please address with some specificity your own wishes and how they relate to UChicago. (no word limit)." , word_limit: "no limit"
      },
      {
        id: 2,
        name: "Optional - Exponents and square roots, pencils and erasers, beta decay and electron capture. Name two things that undo each other and explain why both are necessary. – Inspired by Emmett Cho, Class of 2027 (no word limit)." , word_limit: "no limit"
      },
      {
        id: 3,
        name: "Optional - 'Where have all the flowers gone?' – Pete Seeger. Pick a question from a song title or lyric and give it your best answer. – Inspired by Ryan Murphy, AB’21 (no word limit)." , word_limit: "no limit"
      },
      {
        id: 4,
        name: "Optional - 'Vlog,' 'Labradoodle,' and 'Fauxmage.' Language is filled with portmanteaus. Create a new portmanteau and explain why those two things are a 'patch' (perfect match). – Inspired by Garrett Chalfin, Class of 2027 (no word limit)." , word_limit: "no limit"
      },
      {
        id: 5,
        name: "Optional - A jellyfish is not a fish. Cat burglars don’t burgle cats. Rhode Island is not an island. Write an essay about some other misnomer, and either come up with and defend a new name for it or explain why its inaccurate name should be kept. – Inspired by Sonia Chang, Class of 2025, and Mirabella Blair, Class of 2027 (no word limit)." , word_limit: "no limit"
      },
      {
        id: 6,
        name: "Optional - Despite their origins in the Gupta Empire of India or Ancient Egypt, games like chess or bowling remain widely enjoyed today. What modern game do you believe will withstand the test of time, and why? – Inspired by Adam Heiba, Class of 2027 (no word limit)." , word_limit: "no limit"
      },
      {
        id: 7,
        name: "Optional - There are unwritten rules that everyone follows or has heard at least once in their life. But of course, some rules should be broken or updated. What is an unwritten rule that you wish didn’t exist? (Our custom is to have five new prompts each year, but this year we decided to break with tradition. Enjoy!) – Inspired by Maryam Abdella, Class of 2026 (no word limit)." , word_limit: "no limit"
      },
      {
        id: 8,
        name: "Optional - And, as always… the classic choose your own adventure option! In the spirit of adventurous inquiry, choose one of our past prompts (or create a question of your own). Be original, creative, thought-provoking. Draw on your best qualities as a writer, thinker, visionary, social critic, sage, citizen of the world, or future citizen of the University of Chicago; take a little risk, and have fun! (no word limit)." , word_limit: "no limit"
      }
    ]
  },
  {
    "id": 14,
    "name": "Emory University",
    "imageUrl": "https://upload.wikimedia.org/wikipedia/en/6/63/Emory_University_Seal.svg",
    "meta_data": {"acceptance_rate": "16.2%", "status": "LOW"},
    "prompts": [
        {"id": 1, "name": "What academic areas are you interested in exploring at Emory University and why? (200 words)", word_limit: "200 words"},
        {"id": 2, "name": "Getting to know you Category (150 words): Which book, character, song, monologue, or piece of work (fiction or non-fiction) seems made for you? Why?", word_limit: "150 words"},
        {"id": 3, "name": "Getting to know you Category (150 words): Reflect on a personal experience where you intentionally expanded your cultural awareness.", word_limit: "150 words"},
        {"id": 4, "name": "Getting to know you Category (150 words): Emory University aspires for all students to flourish on campus. Reflect on what flourishing at Emory means to you.", word_limit: "150 words"},
        {"id": 5, "name": "Getting to know you Category (150 words): Emory University's core mission calls for service to humanity. Share how you might personally contribute to this mission.", word_limit: "150 words"},
        {"id": 6, "name": "Getting to know you Category (150 words): Emory University has a strong commitment to building community. Tell us about a community you have been part of where your participation helped to change or shape the community for the better.", word_limit: "150 words"},
        {"id": 7, "name": "Getting to know you Category (150 words): Reflection is a central tenet of Emory University's values. Craft a personal email giving advice to yourself in your first year of high school.", word_limit: "150 words"}
    ]
},
{
    "id": 15,
    "name": "University of Virginia",
    "imageUrl": "https://upload.wikimedia.org/wikipedia/commons/1/17/University_of_Virginia_seal.svg",
    "meta_data": {"acceptance_rate": "16.3%", "status": "LOW"},
    "prompts": [
        {"id": 1, "name": "Optional: If you have a personal or historic connection with UVA, and if you’d like to share how your experience of this connection has prepared you to contribute to the University, please share your thoughts here. Such relationships might include, but are not limited to, being a child of someone who graduated from or works for UVA, a descendant of ancestors who labored at UVA, or a participant in UVA programs. (100 words)", word_limit: "100 words"},
        {"id": 2, "name": "In around 250 words, please answer the following question. What about your individual background, perspective, or experience will serve as a source of strength for you or those around you at UVA? Feel free to write about any past experience or part of your background that has shaped your perspective and will be a source of strength, including but not limited to those related to your community, upbringing, educational environment, race, gender, or other aspects of your background that are important to you. (300 words)", word_limit: "300 words"}
    ]
},
{
    "id": 16,
    "name": "Washington University in St. Louis",
    "imageUrl": "https://upload.wikimedia.org/wikipedia/en/d/d7/WashU_St._Louis_seal.svg",
    "meta_data": {"acceptance_rate": "11.95%", "status": "LOW"},
    "prompts": [
        {"id": 1, "name": "Please tell us what you are interested in studying at college and why. (200 words)", word_limit: "200 words"},
        {"id": 2, "name": "Beyond Boundaries Program (250 words): The Beyond Boundaries Program prepares students to address complex global challenges requiring diverse perspectives and skill sets. What societal issue would you like to tackle? Who would you need on your team, and what would you contribute?", word_limit: "250 words"},
        {"id": 3, "name": "Joint Program in Business + Computer Science (200 words): Students in this program gain foundational knowledge in computer science and business. This unique degree explores the intersection of these two disciplines. How would you use this combined degree to explore their intersection?", word_limit: "200 words"},
        {"id": 4, "name": "Optional (250 words): Discuss a fresh perspective or opinion you brought to a collaborative setting or project.", word_limit: "250 words"},
        {"id": 5, "name": "Optional (250 words): Describe a community you are a part of and your role within it.", word_limit: "250 words"},
        {"id": 6, "name": "Optional (250 words): Explain how your identity has influenced the way you view or interact with your community.", word_limit: "250 words"}
    ]
},
{
    "id": 17,
    "name": "University of Southern California",
    "imageUrl": "https://upload.wikimedia.org/wikipedia/en/b/bc/University_of_Southern_California_seal.svg",
    "meta_data": {"acceptance_rate": "9.75%", "status": "LOW"},
    "prompts": [
        {"id": 1, "name": "Describe how you plan to pursue your academic interests at USC, addressing your first- and second-choice major selections. (250 words)", word_limit: "250 words"}
    ]
},
{
    "id": 18,
    "name": "University of Texas at Austin",
    "imageUrl": "https://upload.wikimedia.org/wikipedia/en/e/e1/University_of_Texas_at_Austin_seal.svg",
    "meta_data": {"acceptance_rate": "29.12%", "status": "MEDIUM"},
    "prompts": [
        {"id": 1, "name": "Why are you interested in the major you indicated as your first-choice major?", word_limit: "no word limit"},
        {"id": 2, "name": "Describe how your experiences, perspectives, talents, and/or leadership activities will help you make an impact at UT.", word_limit: "no word limit"},
        {"id": 3, "name": "Share how you believe your experience at UT-Austin will prepare you to “Change the World” after you graduate.", word_limit: "no word limit"}
    ]
},
{
    "id": 19,
    "name": "Georgia Institute of Technology",
    "imageUrl": "https://upload.wikimedia.org/wikipedia/commons/6/6c/Georgia_Tech_seal.svg",
    "meta_data": {"acceptance_rate": "16%", "status": "LOW"},
    "prompts": [
        {"id": 1, "name": "Why do you want to study your chosen major specifically at Georgia Tech? (max 300 words)", word_limit: "300 words"}
    ]
},
{
    "id": 20,
    "name": "New York University",
    "imageUrl": "https://upload.wikimedia.org/wikipedia/en/1/16/New_York_University_Seal.svg",
    "meta_data": {"acceptance_rate": "8%", "status": "LOW"},
    "prompts": [
        {"id": 1, "name": "Choose one quote and explain why it inspires you, or share a short quote and person not on our list who inspires you, and include why. (250 words)", word_limit: "250 words"},
        {"id": 2, "name": "Optional-Describe how you have enacted change in your community and your motivation for doing so. (200 words)", word_limit: "200 words"}
    ]
},
{
    "id": 21,
    "name": "University of Illinois Urbana-Champaign",
    "imageUrl": "https://upload.wikimedia.org/wikipedia/commons/a/af/University_of_Illinois_seal.svg",
    "meta_data": {"acceptance_rate": "45%", "status": "MEDIUM"},
    "prompts": [
        {"id": 1, "name": "Explain an experience related to your first-choice major from the past 3 to 4 years. (max 150 words)", word_limit: "150 words"},
        {"id": 2, "name": "Describe your personal and/or career goals post-graduation and how your selected major will help you achieve them. (max 150 words)", word_limit: "150 words"}
    ]
},
{
    "id": 22,
    "name": "University of Wisconsin—Madison",
    "imageUrl": "https://upload.wikimedia.org/wikipedia/commons/a/aa/University_of_Wisconsin_seal.svg",
    "meta_data": {"acceptance_rate": "49%", "status": "MEDIUM"},
    "prompts": [
        {"id": 1, "name": "Tell us about something you’ve done and what you’ve learned from it. (approx. 1 page)", word_limit: "approx. 1 page"},
        {"id": 2, "name": "Tell us why you decided to apply to UW-Madison and your interest in the selected major(s). (650 words max)", word_limit: "650 words max"}
    ]
},
{
    "id": 23,
    "name": "Boston College",
    "imageUrl": "https://upload.wikimedia.org/wikipedia/en/0/00/Boston_College_seal.svg",
    "meta_data": {"acceptance_rate": "15.45%", "status": "LOW"},
    "prompts": [
        {"id": 1, "name": "Recommend a book by a living author for your incoming class to read, and explain its importance. (400 words)", word_limit: "400 words"},
        {"id": 2, "name": "Discuss a conversation partner who challenges your perspectives. Cite a specific conversation where they challenged you or vice versa. (400 words)", word_limit: "400 words"},
        {"id": 3, "name": "Discuss a time when someone defined you by a single story. What challenges did this present and how did you overcome them? (400 words)", word_limit: "400 words"},
        {"id": 4, "name": "Which of today’s local or global issues concern you and how might your Boston College education address it? (400 words)", word_limit: "400 words"}
    ]
},
{
  "id": 24,
  "name": "Rice University",
  "imageUrl": "https://upload.wikimedia.org/wikipedia/commons/b/bc/Rice_Owls_logo.svg",
  "meta_data": {
    "acceptance_rate": "9.5%",
    "status": "LOW"
  },
  "prompts": [
    {
      "id": 1,
      "name": "Please explain why you wish to study in the academic areas you selected.",
      "word_limit": "150 words"
    },
    {
      "id": 2,
      "name": "Based upon your exploration of Rice University, what elements of the Rice experience appeal to you?",
      "word_limit": "150 words"
    },
    {
      "id": 3,
      "name": "The Residential College System is at the heart of Rice student life and is heavily influenced by the particular cultural traditions and unique life experiences each student brings. What life experiences and/or unique perspectives are you looking forward to sharing with fellow Owls in the residential college system?",
      "word_limit": "500 words"
    },
    {
      "id": 4,
      "name": "Rice is strengthened by its diverse community of learning and discovery that produces leaders and change agents across the spectrum of human endeavor. What perspectives shaped by your background, experiences, upbringing, and/or racial identity inspire you to join our community of change agents at Rice?",
      "word_limit": "500 words"
    },
    {
      "id": 5,
      "name": "In keeping with Rice’s long-standing tradition (known as 'The Box'), please share an image of something that appeals to you. See the Help Section for more information.",
      "word_limit": "500 words"
    }
  ]
},
{
  "id": 25,
  "name": "Dartmouth College",
  "imageUrl": "https://upload.wikimedia.org/wikipedia/en/e/e4/Dartmouth_College_shield.svg",
  "meta_data": {
    "acceptance_rate": "6%",
    "status": "LOW"
  },
  "prompts": [
    {
      "id": 1,
      "name": "Dartmouth celebrates the ways in which its profound sense of place informs its profound sense of purpose. As you seek admission to Dartmouth’s Class of 2027, what aspects of the College’s academic program, community, or campus environment attract your interest? In short, Why Dartmouth? Please respond in 100 words or fewer.",
      "word_limit": "100 words"
    },
    {
      "id": 2,
      "name": "There is a Quaker saying: Let your life speak. Describe the environment in which you were raised and the impact it has had on the person you are today.",
      "word_limit": "250 words"
    },
    {
      "id": 3,
      "name": "‘Be yourself,’ Oscar Wilde advised. ‘Everyone else is taken.’ Introduce yourself.",
      "word_limit": "250 words"
    },
    {
      "id": 4,
      "name": "What excites you?",
      "word_limit": "250 words"
    },
    {
      "id": 5,
      "name": "Labor leader and civil rights activist Dolores Huerta recommended a life of purpose. ‘We must use our lives to make the world a better place to live, not just to acquire things,’ she said. ‘That is what we are put on the earth for.’ In what ways do you hope to make—or are you already making—an impact? Why? How?",
      "word_limit": "250 words"
    },
    {
      "id": 6,
      "name": "Dr. Seuss, aka Theodor Geisel of Dartmouth’s Class of 1925, wrote, ‘Think and wonder. Wonder and think.’ As you wonder and think, what’s on your mind?",
      "word_limit": "250 words"
    },
    {
      "id": 7,
      "name": "Celebrate your nerdy side.",
      "word_limit": "250 words"
    },
    {
      "id": 8,
      "name": "‘It’s not easy being green…’ was the frequent refrain of Kermit the Frog. How has difference been a part of your life, and how have you embraced it as part of your identity and outlook?",
      "word_limit": "250 words"
    },
    {
      "id": 9,
      "name": "As noted in the College’s mission statement, ‘Dartmouth educates the most promising students and prepares them for a lifetime of learning and of responsible leadership…’ Promise and potential are important aspects of the assessment of any college application, but they can be elusive qualities to capture. Highlight your potential and promise for us; what would you like us to know about you?",
      "word_limit": "250 words"
    }
  ]
},
{
  "id": 26,
  "name": "Vanderbilt University",
  "imageUrl": "https://upload.wikimedia.org/wikipedia/commons/d/d0/Vanderbilt_University_logo.svg",
  "meta_data": {
    "acceptance_rate": "6.3%",
    "status": "LOW"
  },
  "prompts": [
    {
      "id": 1,
      "name": "Vanderbilt offers a community where students find balance between their academic and social experiences. Please briefly elaborate on how one of your extracurricular activities or work experiences has influenced you.",
      "word_limit": "250 words"
    },
    {
      "id": 2,
      "name": "Vanderbilt University values learning through contrasting points of view. We understand that our differences, and our respect for alternative views and voices, are our greatest source of strength. Please reflect on conversations you’ve had with people who have expressed viewpoints different from your own. How did these conversations/experiences influence you?",
      "word_limit": "250 words"
    }
  ]
},
{
  "id": 27,
  "name": "Notre Dame",
  "imageUrl": "https://upload.wikimedia.org/wikipedia/commons/f/f5/Notre_Dame_Fighting_Irish_logo.svg",
  "meta_data": {
    "acceptance_rate": "12%",
    "status": "LOW"
  },
  "prompts": [
    {
      "id": 1,
      "name": "Notre Dame fosters an undergraduate experience dedicated to the intellectual, moral, and spiritual development of each individual, characterized by a collective sense of care for every person. How do you foster service to others in your community?",
      "word_limit": "150 words"
    },
    {
      "id": 2,
      "name": "What is distinctive about your personal experiences and development (e.g., family support, culture, disability, personal background, community, etc)? Why are these experiences important to you and how will you enrich the Notre Dame community?",
      "word_limit": "150 words"
    },
    {
      "id": 3,
      "name": "Describe a time when you advocated for something you believed in and influenced others through thoughtful discourse to promote a deeper understanding of a difficult situation.",
      "word_limit": "150 words"
    }
  ]
},
{
  "id": 28,
  "name": "University of Michigan",
  "imageUrl": "https://upload.wikimedia.org/wikipedia/commons/c/c1/University_of_Michigan_logo.svg",
  "meta_data": {
    "acceptance_rate": "17.7%",
    "status": "LOW"
  },
  "prompts": [
    {
      "id": 1,
      "name": "Everyone belongs to many different communities and/or groups defined by (among other things) shared geography, religion, ethnicity, income, cuisine, interest, race, ideology, or intellectual heritage. Choose one of the communities to which you belong, and describe that community and your place within it.",
      "word_limit": "300 words"
    },
    {
      "id": 2,
      "name": "Describe the unique qualities that attract you to the specific undergraduate College or School (including preferred admission and dual degree programs) to which you are applying at the University of Michigan. How would that curriculum support your interests?",
      "word_limit": "500 words"
    }
  ]
},
{
  "id": 29,
  "name": "Georgetown University",
  "imageUrl": "https://upload.wikimedia.org/wikipedia/commons/d/d9/Georgetown_University_seal.svg",
  "meta_data": {
    "acceptance_rate": "14.05%",
    "status": "LOW"
  },
  "prompts": [
    {
      "id": 1,
      "name": "Please elaborate on any special talents or skills you would like to highlight.",
      "word_limit": "250 words"
    },
    {
      "id": 2,
      "name": "Briefly discuss the significance to you of the school or summer activity in which you have been most involved.",
      "word_limit": "350 words"
    }
  ]
},
{
  "id": 30,
  "name": "University of North Carolina Chapel Hill",
  "imageUrl": "https://upload.wikimedia.org/wikipedia/en/5/5c/University_of_North_Carolina_at_Chapel_Hill_seal.svg",
  "meta_data": {
    "acceptance_rate": "17%",
    "status": "LOW"
  },
  "prompts": [
    {
      "id": 1,
      "name": "Discuss one of your personal qualities and share a story, anecdote, or memory of how it helped you make a positive impact on a community. This could be your current community or another community you have engaged.",
      "word_limit": "250 words"
    },
    {
      "id": 2,
      "name": "Discuss an academic topic that you’re excited to explore and learn more about in college. Why does this topic interest you? Topics could be a specific course of study, research interests, or any other area related to your academic experience in college.",
      "word_limit": "250 words"
    }
  ]
},
{
  "id": 31,
  "name": "Carnegie Mellon University",
  "imageUrl": "https://www.cmu.edu/brand/brand-guidelines/images/seal-4c-600x600-min.jpg",
  "meta_data": {
    "acceptance_rate": "11%",
    "status": "LOW"
  },
  "prompts": [
    {
      "id": 1,
      "name": "Many students pursue college for a specific degree, career opportunity or personal goal. Whichever it may be, learning will be critical to achieve your ultimate goal. As you think ahead to the process of learning during your college years, how will you define a successful college experience?",
      "word_limit": "300 words"
    },
    {
      "id": 2,
      "name": "Most students choose their intended major or area of study based on a passion or inspiration that’s developed over time – what passion or inspiration led you to choose this area of study?",
      "word_limit": "300 words"
    },
    {
      "id": 3,
      "name": "Consider your application as a whole. What do you personally want to emphasize about your application for the admission committee’s consideration? Highlight something that’s important to you or something you haven’t had a chance to share. Tell us, don’t show us.",
      "word_limit": "300 words"
    }
  ]
},
{
  "id": 32,
  "name": "Tufts University",
  "imageUrl": "https://upload.wikimedia.org/wikipedia/commons/1/12/Tufts_University_wordmark.svg",
  "meta_data": {
    "acceptance_rate": "9.5%",
    "status": "LOW"
  },
  "prompts": [
    {
      "id": 1,
      "name": "It’s cool to love learning. What excites your intellectual curiosity?",
      "word_limit": "250 words"
    },
    {
      "id": 2,
      "name": "How have the environments or experiences of your upbringing – your family, home, neighborhood, or community – shaped the person you are today?",
      "word_limit": "250 words"
    },
    {
      "id": 3,
      "name": "Using a specific example or two, tell us about a way that you contributed to building a collaborative and/or inclusive community.",
      "word_limit": "250 words"
    },
    {
      "id": 4,
      "name": "SMFA Art has the power to disrupt our preconceptions, shape public discourse, and imagine new ways of being in the world. What are the ideas you’d like to explore in your work?",
      "word_limit": "250 words"
    },
    {
      "id": 5,
      "name": "‘I am applying to Tufts because…’",
      "word_limit": "100 words"
    }
  ]
},
{
  "id": 33,
  "name": "University of Washington",
  "imageUrl": "https://upload.wikimedia.org/wikipedia/en/5/58/University_of_Washington_seal.svg",
  "meta_data": {
    "acceptance_rate": "53.5%",
    "status": "MEDIUM"
  },
  "prompts": [
    {
      "id": 1,
      "name": "Tell a story from your life, describing an experience that either demonstrates your character or helped to shape it.",
      "word_limit": "650 words"
    },
    {
      "id": 2,
      "name": "Our families and communities often define us and our individual worlds. Community might refer to your cultural group, extended family, religious group, neighborhood or school, sports team or club, co-workers, etc. Describe the world you come from and how you, as a product of it, might add to the diversity of the University of Washington.",
      "word_limit": "300 words"
    }
  ]
},
{
  "id": 34,
  "name": "Boston University",
  "imageUrl": "https://upload.wikimedia.org/wikipedia/commons/f/f5/Boston_University_seal.svg",
  "meta_data": 
  {"acceptance_rate": "14%",
  "status": "LOW"},
  "prompts": [
    {
      "id": 1,
      "name": "Reflect on a social or community issue that deeply resonates with you. Why is it important to you, and how have you been involved in addressing or raising awareness about it?",
      "word_limit": "300 words"
    },
    {
      "id": 2,
      "name": "What about being a student at BU most excites you? How do you hope to contribute to our campus community?",
      "word_limit": "300 words"
    }
  ]
  },
  {
  "id": 35,
  "name": "The Ohio State University",
  "imageUrl": "https://upload.wikimedia.org/wikipedia/en/e/e1/Ohio_State_University_seal.svg",
  "meta_data": {
    "acceptance_rate": "53%",
    "status": "HIGH"
  },
  "prompts": [
    {
      "id": 1,
      "name": "(Morrill Scholarship Program Short Response) In what ways have your life experiences and/or endeavors prepared you to be an active Morrill Scholar who will champion OSU’s Shared Values of “diversity and innovation” and “inclusion and equity” while investing in our culture of service reflective of our land grant mission?  Please answer fully, and when possible, provide specific examples.",
      "word_limit": "500 words"
    }
  ]
},
    {
  "id": 36,
  "name": "Purdue University",
  "imageUrl": "https://upload.wikimedia.org/wikipedia/en/6/61/Purdue_University_seal.svg",
  "meta_data": {
    "acceptance_rate": "53%",
    "status": "HIGH"
  },
  "prompts": [
    {
      "id": 1,
      "name": "How will opportunities at Purdue support your interests, both in and out of the classroom?",
      "word_limit": "250 words"
    },
    {
      "id": 2,
      "name": "Briefly discuss your reasons for pursuing the major you have selected.",
      "word_limit": "250 words"
    }
  ]
  },
  {
  "id": 37,
  "name": "University of Maryland, College Park",
  "imageUrl": "https://upload.wikimedia.org/wikipedia/en/3/3e/University_of_Maryland_seal.svg",
  "meta_data": {
    "acceptance_rate": "52%",
    "status": "MEDIUM"
  },
  "prompts": [
    {
      "id": 1,
      "name": "If I could travel anywhere, I would go to…",
      "word_limit": "650 words"
    },
    {
      "id": 2,
      "name": "The most interesting fact I ever learned from research was…",
      "word_limit": "650 words"
    },
    {
      "id": 3,
      "name": "In addition to my major, my academic interests include…",
      "word_limit": "650 words"
    },
    {
      "id": 4,
      "name": "My favorite thing about last Thursday was…",
      "word_limit": "650 words"
    },
    {
      "id": 5,
      "name": "Something you might not know about me is…",
      "word_limit": "650 words"
    }
  ]
  },
  {
  "id": 38,
  "name": "Lehigh University",
  "imageUrl": "https://upload.wikimedia.org/wikipedia/en/e/ef/LUwithShield-CMYK.svg",
  "meta_data": {
    "acceptance_rate": "37%",
    "status": "MEDIUM"
  },
  "prompts": [
    {
      "id": 1,
      "name": "How did you first learn about Lehigh University and what motivated you to apply?",
      "word_limit": "150 words"
    },
    {
      "id": 2,
      "name": "How will the unique combination of college, program, major and/or 4+1 program that you selected above allow you to achieve your educational or professional goals?",
      "word_limit": "200 words"
    },
    {
      "id": 3,
      "name": "What would you want to be different in your own community (local, school, religious, academic, etc.) or the broader global community to further principles of equality or equity?",
      "word_limit": "300 words"
    },
    {
      "id": 4,
      "name": "What factors have most shaped who you are and what you believe today? You might discuss an obstacle that you have overcome or an experience that has inspired you.",
      "word_limit": "300 words"
    },
    {
      "id": 5,
      "name": "Share with us a part of your personal background, perspective, experience or academic interest that has shaped you as a person and that would uniquely add to the Lehigh community.",
      "word_limit": "300 words"
    }
  ]
  },
  {
  "id": 39,
  "name": "Texas A&M University",
  "imageUrl": "https://upload.wikimedia.org/wikipedia/en/f/f7/Texas_A%26M_University_seal.svg",
  "meta_data": {
    "acceptance_rate": "64%",
    "status": "HIGH"
  },
  "prompts": [
    {
      "id": 1,
      "name": "Describe a life event which you feel has prepared you to be successful in college.",
      "word_limit": "250 words"
    },
    {
      "id": 2,
      "name": "Tell us about the person who has most impacted your life and why.",
      "word_limit": "250 words"
    },
    {
      "id": 3,
      "name": "For Engineering Applicants: Describe your academic and career goals in the broad field of engineering (including computer science, industrial distribution, and engineering technology). What and/or who has influenced you either inside or outside the classroom that contributed to these goals? It is important to spend time addressing this question as it will be considered as part of the engineering review process.",
      "word_limit": "500 words"
    }
  ]
},

{
  "id": 40,
  "name": "University of Georgia",
  "imageUrl": "https://upload.wikimedia.org/wikipedia/en/2/2d/University_of_Georgia_seal.svg",
  "meta_data": {
    "acceptance_rate": "42%",
    "status": "MEDIUM"
  },
  "prompts": [
    {
      "id": 1,
      "name": "The transition from middle to high school is a key time for students as they reach new levels of both academic and personal discovery. Please share a book (novel, non-fiction, etc.) that had a serious impact on you during this time. Please focus more on why this book made an impact on you and less on the plot/theme of the book itself (we are not looking for a book report).",
      "word_limit": "300 words"
    }
  ]
},
{
  "id": 41,
  "name": "University of Rochester",
  "imageUrl": "https://upload.wikimedia.org/wikipedia/en/c/c8/University_of_Rochester_seal.svg",
  "meta_data": {
    "acceptance_rate": "39%",
    "status": "MEDIUM"
  },
  "prompts": [
    {
      "id": 1,
      "name": "The University of Rochester motto of Meliora – or “ever better” - deeply integrates critical core values into all that we do. These core values of equity, leadership, integrity, openness, respect and accountability define not only who we are, but also who we hope to become. Please use the space below to highlight a time, creative idea or research experience when you put into action one or more of these characteristics in order to make yourself, your community or the world ever better.",
      "word_limit": "250 words"
    },
    {
      "id": 2,
      "name": "American social reformer, abolitionist, writer and statesman Frederick Douglass said, “Some know the value of education by having it. I knew its value by not having it.” Explain ways in which education has directly influenced you and your ability to do good in the world. How will you use the curricular flexibility and co-curricular opportunities of the University of Rochester experience to grow and to promote positive change for yourself, your community and the world?",
      "word_limit": "200 words"
    },
    {
      "id": 3,
      "name": "Dr. Donna Strickland, University of Rochester alum and 2018 Nobel Prize winner in Physics said, “There’s no point in me being anything other than me.” The University of Rochester encourages each student to embrace who they are and create their own individual curricular path and experience. How will you use the opportunities here to fully be who you are? What unique perspectives will you bring to our community?",
      "word_limit": "200 words"
    },
    {
      "id": 4,
      "name": "Susan B. Anthony, champion of abolition and women’s rights, once said “Organize, agitate, educate must be our war cry.” As you look to join our community of doers and disruptors, in what ways do you envision using both the curricular flexibility and co-curricular opportunities to invoke change for marginalized groups?",
      "word_limit": "200 words"
    },
    {
      "id": 5,
      "name": "The University of Rochester benefactor entrepreneur, photography pioneer and philanthropist George Eastman said, “What we do during our working hours determines what we have; what we do in our leisure hours determines what we are.” Looking forward towards your college experience, what do you hope to do outside of the classroom at the University of Rochester that will enhance who you are as a person? How will specific academic and social opportunities here help you grow?",
      "word_limit": "200 words"
    }
  ]
},
{
  "id": 42,
  "name": "Virginia Tech",
  "imageUrl": "https://upload.wikimedia.org/wikipedia/en/5/54/Virginia_Tech_seal.svg",
  "meta_data": {
    "acceptance_rate": "57%",
    "status": "HIGH"
  },
  "prompts": [
    {
      "id": 1,
      "name": "Virginia Tech’s motto is “Ut Prosim” which means ‘That I May Serve’. Share how you contribute to a community that is important to you. How long have you been involved? What have you learned and how would you like to share that with others at Virginia Tech?",
      "word_limit": "120 words"
    },
    {
      "id": 2,
      "name": "Virginia Tech’s Principles of Community supports access and inclusion by affirming the dignity and value of every person, respecting differences, promoting mutual understanding and open expression, and strives to eliminate bias and discrimination. Reflect on a time when you were not able or allowed to express a different or diverse position or opinion (or you witnessed another person or group experience the same situation)? How did you respond or wish you would have responded? Did your viewpoint change in any way after this experience?",
      "word_limit": "120 words"
    },
    {
      "id": 3,
      "name": "Share a time when you were most proud of yourself either as a role model or when you displayed your leadership. What specific skills did you contribute to the experience? How did others rely on you for guidance? What did you learn about yourself during this time?",
      "word_limit": "120 words"
    },
    {
      "id": 4,
      "name": "Describe a goal that you have set and the steps you will take to achieve it. What made you set this goal for yourself? What is your timeline to achieve this goal? Who do you seek encouragement or guidance from as you work on this goal?",
      "word_limit": "120 words"
    }
  ]
},
{
  "id": 43,
  "name": "Wake Forest University",
  "imageUrl": "https://upload.wikimedia.org/wikipedia/en/0/0a/Wake_Forest_University_seal.svg",
  "meta_data": {
    "acceptance_rate": "20%",
    "status": "LOW"
  },
  "prompts": [
    {
      "id": 1,
      "name": "Why have you decided to apply to Wake Forest? Share with us anything that has made you interested in our institution.",
      "word_limit": "150 words"
    },
    {
      "id": 2,
      "name": "Tell us what piques your intellectual curiosity or has helped you understand the world’s complexity. This can include a work you’ve read, a project you’ve completed for a class, and even co-curricular activities in which you have been involved.",
      "word_limit": "150 words"
    },
    {
      "id": 3,
      "name": "Dr. Maya Angelou, renowned author, poet, civil-rights activist, and former Wake Forest University Reynolds Professor of American Studies, inspired others to celebrate their identities and to honor each person’s dignity. Choose one of Dr. Angelou’s powerful quotes. How does this quote relate to your lived experience or reflect how you plan to contribute to the Wake Forest community?",
      "word_limit": "300 words"
    }
  ]
},
{
  "id": 44,
  "name": "Case Western Reserve University",
  "imageUrl": "https://upload.wikimedia.org/wikipedia/en/0/08/Case_Western_Reserve_University_seal.svg",
  "meta_data": {
    "acceptance_rate": "27%",
    "status": "MEDIUM"
  },
  "prompts": [
    {
      "id": 1,
      "name": "(Scholarship Optional)In the college application process, you are constantly prompted for a list of your achievements, awards, and accomplishments. While this information is useful to us, we are interested in hearing more about you. Describe an event, achievement, or experience of which you are particularly proud but that will not show up on a resume, may not garner any recognition, and does not appear anywhere else on your admission application.Why have you decided to apply to Wake Forest? Share with us anything that has made you interested in our institution.",
      "word_limit": "750 words"
    },
    {
      "id": 2,
      "name": "By applying to the Pre-Professional Scholars Program, you are applying to gain admission to professional school earlier than students who apply in the traditional way. Please indicate why you’re interested in your chosen profession. How do you see yourself being particularly suited to this field? What events and/or experiences have led you to your choice?",
      "word_limit": "500 words"
    }
  ]
},
{
  "id": 45,
  "name": "Florida State University",
  "imageUrl": "https://upload.wikimedia.org/wikipedia/en/6/6b/Florida_State_University_seal.svg",
  "meta_data": {
    "acceptance_rate": "24%",
    "status": "MEDIUM"
  },
  "prompts": [
    {
      "id": 1,
      "name": "Describe your most meaningful commitment outside of the classroom in high school",
      "description": "Please provide more details on your most meaningful commitment outside of the classroom while in high school and explain why it was meaningful. This could be related to an extracurricular activity, work, volunteering, an academic activity, family responsibility, or any other non-classroom activity.",
      "word_limit": "250 words"
    }
  ]
},
{
  "id": 46,
  "name": "University of Minnesota Twin Cities",
  "imageUrl": "https://upload.wikimedia.org/wikipedia/commons/8/86/Seal_of_the_University_of_Minnesota.svg",
  "meta_data": {
    "acceptance_rate": "75%",
    "status": "HIGH"
  },
  "prompts": [
    {
      "id": 1,
      "name": "Explanation of Major Selection",
      "description": "Please include an explanation of why you would like to study the major(s) you have selected. You may also use this space to indicate your interest in other major(s) in the colleges listed above.",
      "word_limit": "Maximum 150 words"
    },
    {
      "id": 2,
      "name": "Contribution to Community of Scholars",
      "description": "The University values diversity, broadly defined to include diversity of experiences, perspectives, backgrounds, and talents. Enrolling a diverse community of scholars interested in learning with and from each other fosters discussion and discovery inside and outside of the classroom. Please share briefly how you might contribute to, or benefit from, our community of scholars.",
      "word_limit": "150 words"
    }
  ]
},
{
  "id": 47,
  "name": "College of William & Mary",
  "imageUrl": "https://upload.wikimedia.org/wikipedia/en/d/d7/College_of_William_%26_Mary_Coat_of_Arms.png",
  "meta_data": {
    "acceptance_rate": "34%",
    "status": "MEDIUM"
  },
  "prompts": [
    {
      "id": 1,
      "name": "What Makes You Unique and Colorful",
      "description": "Beyond your impressive academic credentials and extracurricular accomplishments, what else makes you unique and colorful?",
      "word_limit": "300 words"
    },
    {
      "id": 2,
      "name": "Importance of Communities to You",
      "description": "Are there any particular communities that are important to you, and how do you see yourself being a part of our community?",
      "word_limit": "300 words"
    }
  ]
},
{
  "id": 49,
  "name": "Michigan State University",
  "imageUrl": "https://upload.wikimedia.org/wikipedia/en/5/53/Michigan_State_University_seal.svg",
  "meta_data": {
    "acceptance_rate": "71%",
    "status": "HIGH"
  },
  "prompts": [
    {
      "id": 1,
      "name": "Community and Your Place Within It",
      "description": "Everyone belongs to many different communities and/or groups defined by (among other things) shared geography, religion, ethnicity, income, cuisine, interest, race, ideology, or intellectual heritage. Choose one of the communities to which you belong, and describe that community and your place within it.",
      "word_limit": "300 words"
    },
    {
      "id": 2,
      "name": "Attraction to Specific Undergraduate College or School",
      "description": "Describe the unique qualities that attract you to the specific undergraduate College or School (including preferred admission and dual degree programs) to which you are applying at the University of Michigan. How would that curriculum support your interests?",
      "word_limit": "550 words"
    }
  ]
},
{
  "id": 50,
  "name": "North Carolina State University",
  "imageUrl": "https://upload.wikimedia.org/wikipedia/en/2/24/North_Carolina_State_University_seal.svg",
  "meta_data": {
    "acceptance_rate": "47%",
    "status": "MEDIUM"
  },
  "prompts": [
    {
      "id": 1,
      "name": "Academic Program Selection",
      "description": "Explain why you selected the academic program(s) above and why you are interested in studying these at NC State",
      "word_limit": "250 words"
    },
    {
      "id": 2,
      "name": "Overcoming Obstacles and Hardships",
      "description": "Discuss any other obstacles and/or hardships that you have encountered that have affected you personally or academically and how you dealt with them",
      "word_limit": "250 words"
    },
    {
      "id": 3,
      "name": "Contribution to a Diverse and Inclusive Environment",
      "description": "NC State University is committed to building a just and inclusive community, one that does not tolerate unjust or inhumane treatment, and that denounces it, clearly and loudly. Please describe what those words mean to you and how you will contribute to a more diverse and inclusive NC State environment",
      "word_limit": "250 words"
    }
  ]
},
{
  "id": 51,
  "name": "The Pennsylvania State University",
  "imageUrl": "https://upload.wikimedia.org/wikipedia/en/5/5c/Pennsylvania_State_University_seal.svg",
  "meta_data": {
    "acceptance_rate": "57%",
    "status": "HIGH"
  },
  "prompts": [
    {
      "id": 1,
      "name": "Optional Personal Insight",
      "description": "Optional: Please tell us something about yourself, your experiences, or activities that you believe would reflect positively on your ability to succeed at Penn State. This is your opportunity to tell us something about yourself that is not already reflected in your application or academic records.",
      "word_limit": "500 words"
    }
  ]
},
{
  "id": 52,
  "name": "Santa Clara University",
  "imageUrl": "https://upload.wikimedia.org/wikipedia/en/a/ad/Santa_Clara_U_Seal.svg",
  "meta_data": {
    "acceptance_rate": "48%",
    "status": "MEDIUM"
  },
  "prompts": [
    {
      "id": 1,
      "name": "Addressing an Ethical Dilemma with SCU Education",
      "description": "At Santa Clara University, we push our students to be creative, be challenged, and be the solution. Think about an ethical dilemma that you care about that our society is currently facing. This can be something happening in your local community or more globally. How can an SCU education help you prepare for and address this challenge?",
      "word_limit": "300 words"
    },
    {
      "id": 2,
      "name": "Contribution to a Diverse and Inclusive Community",
      "description": "At Santa Clara University, we value our diverse and inclusive community. Our campus learning environment is enriched by the lived experiences of people from different backgrounds. What people, places, events, or circumstances have shaped the individual you are today and how you could contribute to our community?",
      "word_limit": "300 words"
    }
  ]
}
])



















export const options = ([
  { id: 1, name: 'Princeton University' },
  { id: 2, name: 'MIT' },
  { id: 3, name: 'Harvard University' },
  { id: 4, name: 'Stanford University' },
  { id: 5, name: 'Yale University' },
  { id: 6, name: 'University of Pennsylvania' },
  { id: 7, name: 'Duke University' },
  { id: 8, name: 'California Institute of Technology' },
  { id: 9, name: 'Northwestern University' },
  { id: 10, name: 'Brown University' },
  { id: 11, name: 'Johns Hopkins University' },
  { id: 12, name: 'Cornell University' },
  { id: 13, name: 'University of Chicago' },
  { id: 14, name: 'Emory University' },
  { id: 15, name: 'University of Virginia' },
  { id: 16, name: 'Washington University in St. Louis' },
  { id: 17, name: 'University of Southern California' },
  { id: 18, name: 'University of Texas at Austin' },
  { id: 19, name: 'Georgia Institute of Technology' },
  { id: 20, name: 'New York University' },
  { id: 21, name: 'University of Illinois Urbana-Champaign' },
  { id: 22, name: 'University of Wisconsin—Madison' },
  { id: 23, name: 'Boston College' },
  { id: 24, name: 'Rice University' },
  { id: 25, name: 'Dartmouth College' },
  { id: 26, name: 'Vanderbilt University' },
  { id: 27, name: 'Notre Dame' },
  { id: 28, name: 'University of Michigan' },
  { id: 29, name: 'Georgetown University' },
  { id: 30, name: 'University of North Carolina Chapel Hill' },
  { id: 31, name: 'Carnegie Mellon University' },
  { id: 32, name: 'Tufts University' },
  { id: 33, name: 'University of Washington' },
  { id: 34, name: 'Boston University' },
  { id: 35, name: 'The Ohio State University' },
  { id: 36, name: 'Purdue University' },
  { id: 37, name: 'University of Maryland, College Park' },
  { id: 38, name: 'Lehigh University' },
  { id: 39, name: 'Texas A&M University' },
  { id: 40, name: 'University of Georgia' },
  { id: 41, name: 'University of Rochester' },
  { id: 42, name: 'Virginia Tech' },
  { id: 43, name: 'Wake Forest University' },
  { id: 44, name: 'Case Western Reserve University' },
  { id: 45, name: 'Florida State University' },
  { id: 46, name: 'University of Minnesota Twin Cities' },
  { id: 47, name: 'College of William & Mary' },
  { id: 48, name: 'Brandeis University' },
  { id: 49, name: 'Michigan State University' },
  { id: 50, name: 'North Carolina State University' },
  { id: 51, name: 'The Pennsylvania State University' },
  { id: 52, name: 'Santa Clara University' },
])
